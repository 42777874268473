/**
 * Login
 */
export const LOGIN_USER: any = 'LOGIN_USER';
export const LOGIN_USER_START: any = 'LOGIN_USER_START';
export const LOGIN_USER_SUCCESS: any = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED: any = 'LOGIN_USER_FAILED';
export const LOGIN_USER_RESET: any = 'LOGIN_USER_RESET';
export const LOGOUT_USER: any = 'LOGOUT_USER';

type AuthAction = { type: string; payload: {} | string };

export const loginUser = (payload): AuthAction => ({
  type: LOGIN_USER,
  payload,
});

export const loginUserSuccess = (payload): AuthAction => ({
  type: LOGIN_USER_SUCCESS,
  payload,
});

export const loginUserFailed = (error: string): AuthAction => ({
  type: LOGIN_USER_FAILED,
  payload: error,
});

export const loginUserReset = (): AuthAction => ({
  type: LOGIN_USER_RESET,
  payload: {},
});

export const logoutUser = (): AuthAction => ({
  type: LOGOUT_USER,
  payload: {},
});

/**
 * Register
 */

export const REGISTER_USER: any = 'REGISTER_USER';
export const REGISTER_USER_START: any = 'REGISTER_USER_START';
export const REGISTER_USER_SUCCESS: any = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED: any = 'REGISTER_USER_FAILED';
export const REGISTER_USER_RESET: any = 'REGISTER_USER_RESET';

export const registerUser = (payload): AuthAction => ({
  type: REGISTER_USER,
  payload,
});

export const registerUserSuccess = (payload): AuthAction => ({
  type: REGISTER_USER_SUCCESS,
  payload,
});

export const registerUserFailed = (payload): AuthAction => ({
  type: REGISTER_USER_FAILED,
  payload,
});

export const registerUserReset = (): AuthAction => ({
  type: REGISTER_USER_RESET,
  payload: {},
});

/**
 * Forgot Password
 */

export const FORGET_PASSWORD: any = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_START: any = 'FORGET_PASSWORD_START';
export const FORGET_PASSWORD_SUCCESS: any = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED: any = 'FORGET_PASSWORD_FAILED';
export const FORGET_PASSWORD_RESET: any = 'FORGET_PASSWORD_RESET';

export const forgetPassword = (payload): AuthAction => ({
  type: FORGET_PASSWORD,
  payload,
});

export const forgetPasswordSuccess = (payload): AuthAction => ({
  type: FORGET_PASSWORD_SUCCESS,
  payload,
});

export const forgetPasswordFailed = (payload): AuthAction => ({
  type: FORGET_PASSWORD_FAILED,
  payload,
});

export const forgetPasswordReset = (): AuthAction => ({
  type: FORGET_PASSWORD_RESET,
  payload: {},
});
