import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchPlantsStarted, fetchPlantsFail, fetchPlantsSuccess, FETCH_PLANTS } from './actions';
import { getPlants } from './api';

/**
 * Get All Plants
 */
export function* watchFetchPlants(action: any) {
  yield put(fetchPlantsStarted());
  try {
    const data = yield getPlants(action.payload);
    yield put(fetchPlantsSuccess(data.data));
  } catch (e) {
    yield put(fetchPlantsFail(e.message));
  }
}

export function* fetchPlantsSaga() {
  yield takeEvery(FETCH_PLANTS, watchFetchPlants);
}

/**
 * Plants Saga
 */
function* plantSaga(): any {
  yield all([fork(fetchPlantsSaga)]);
}

export default plantSaga;
