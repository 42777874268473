import { all, fork, put, takeEvery } from 'redux-saga/effects';

import {
  APPROVE_REJECT_CUSTOMER,
  CREATE_CUSTOMER,
  DELETE_CUSTOMER,
  deleteCustomerSuccess,
  FETCH_CUSTOMER,
  FETCH_CUSTOMERS,
  fetchCustomerFail,
  fetchCustomersFail,
  fetchCustomersStarted,
  fetchCustomersSuccess,
  fetchCustomerStarted,
  fetchCustomerSuccess,
  UPDATE_CUSTOMER,
  fetchCustomerUsersStarted,
  fetchCustomerUsersSuccess,
  fetchCustomerUsersFail,
  FETCH_CUSTOMER_USERS,
  fetchCustomerUserStarted,
  fetchCustomerUserSuccess,
  fetchCustomerUserFail,
  FETCH_CUSTOMER_USER,
  CREATE_CUSTOMER_USER,
  UPDATE_CUSTOMER_USER,
} from './actions';
import {
  addCustomer,
  addCustomerUser,
  approveRejectCustomerAPI,
  editCustomer,
  editCustomerUser,
  getCustomer,
  getCustomers,
  getCustomerUser,
  getCustomerUsers,
  removeCustomer,
} from './api';

/**
 * Get All Customers
 */
export function* watchFetchCustomers(action: any) {
  yield put(fetchCustomersStarted());
  try {
    const data = yield getCustomers(action.payload);
    yield put(fetchCustomersSuccess(data.data));
  } catch (e) {
    yield put(fetchCustomersFail(e.message));
  }
}

export function* fetchCustomersSaga() {
  yield takeEvery(FETCH_CUSTOMERS, watchFetchCustomers);
}

/**
 * Get All Customer
 */
export function* watchFetchCustomer(action: any) {
  yield put(fetchCustomerStarted());
  try {
    const data = yield getCustomer(action.payload);
    yield put(fetchCustomerSuccess(data.data));
  } catch (e) {
    yield put(fetchCustomerFail(e.message));
  }
}

export function* fetchCustomerSaga() {
  yield takeEvery(FETCH_CUSTOMER, watchFetchCustomer);
}

/**
 * Create New Customer
 */
export function* watchCreateCustomer(action: any) {
  yield put(fetchCustomerStarted());
  try {
    const data = yield addCustomer(action.payload);
    yield put(fetchCustomerSuccess(data.data));
  } catch (e) {
    yield put(fetchCustomerFail(e.message));
  }
}

export function* createCustomerSaga() {
  yield takeEvery(CREATE_CUSTOMER, watchCreateCustomer);
}

/**
 * Update Customer
 */
export function* watchUpdateCustomer(action: any) {
  yield put(fetchCustomerStarted());
  try {
    const data = yield editCustomer(action.payload);
    yield put(fetchCustomerSuccess(data.data));
  } catch (e) {
    yield put(fetchCustomerFail(e.message));
  }
}

export function* updateCustomerSaga() {
  yield takeEvery(UPDATE_CUSTOMER, watchUpdateCustomer);
}

/**
 * Remove Customer
 */
export function* watchDeleteCustomer(action: any) {
  yield put(fetchCustomerStarted());
  try {
    const data = yield removeCustomer(action.payload);
    yield put(deleteCustomerSuccess(data.data));
  } catch (e) {
    yield put(fetchCustomerFail(e.message));
  }
}

export function* deleteCustomerSaga() {
  yield takeEvery(DELETE_CUSTOMER, watchDeleteCustomer);
}

/**
 * Approve Reject Customer
 */
export function* watchApproveRejectCustomer(action: any) {
  yield put(fetchCustomerStarted());
  try {
    const data = yield approveRejectCustomerAPI(action.payload);
    yield put(fetchCustomerSuccess(data.data));
  } catch (e) {
    yield put(fetchCustomerFail(e.message));
  }
}

export function* approveRejectCustomerSaga() {
  yield takeEvery(APPROVE_REJECT_CUSTOMER, watchApproveRejectCustomer);
}

/**
 * Get All Customer Users
 */
export function* watchFetchCustomerUsers(action: any) {
  yield put(fetchCustomerUsersStarted());
  try {
    const data = yield getCustomerUsers(action.payload);
    yield put(fetchCustomerUsersSuccess(data.data));
  } catch (e) {
    yield put(fetchCustomerUsersFail(e.message));
  }
}

export function* fetchCustomerUsersSaga() {
  yield takeEvery(FETCH_CUSTOMER_USERS, watchFetchCustomerUsers);
}

/**
 * Get Customer User
 */
export function* watchFetchCustomerUser(action: any) {
  yield put(fetchCustomerUserStarted());
  try {
    const data = yield getCustomerUser(action.payload);
    yield put(fetchCustomerUserSuccess(data.data));
  } catch (e) {
    yield put(fetchCustomerUserFail(e.message));
  }
}

export function* fetchCustomerUserSaga() {
  yield takeEvery(FETCH_CUSTOMER_USER, watchFetchCustomerUser);
}

/**
 * Create New Customer User
 */
export function* watchCreateCustomerUser(action: any) {
  yield put(fetchCustomerUserStarted());
  try {
    const data = yield addCustomerUser(action.payload);
    yield put(fetchCustomerUserSuccess(data.data));
  } catch (e) {
    yield put(fetchCustomerUserFail(e.message));
  }
}

export function* createCustomerUserSaga() {
  yield takeEvery(CREATE_CUSTOMER_USER, watchCreateCustomerUser);
}

/**
 * Update Customer User
 */
export function* watchUpdateCustomerUser(action: any) {
  yield put(fetchCustomerUserStarted());
  try {
    const data = yield editCustomerUser(action.payload);
    yield put(fetchCustomerUserSuccess(data.data));
  } catch (e) {
    yield put(fetchCustomerUserFail(e.message));
  }
}

export function* updateUserSaga() {
  yield takeEvery(UPDATE_CUSTOMER_USER, watchUpdateCustomerUser);
}

/**
 * Customer Saga
 */
function* customerSaga(): any {
  yield all([
    fork(fetchCustomersSaga),
    fork(fetchCustomerSaga),
    fork(createCustomerSaga),
    fork(updateCustomerSaga),
    fork(deleteCustomerSaga),
    fork(approveRejectCustomerSaga),
    fork(fetchCustomerUsersSaga),
    fork(fetchCustomerUserSaga),
    fork(createCustomerUserSaga),
    fork(updateUserSaga),
  ]);
}

export default customerSaga;
