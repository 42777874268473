import React from 'react';

/**
 * Renders the preloader
 */
const LoaderComponent = () => {
  return (
    <div className="preloader">
      <div className="status">
        <div className="spinner-border text-primary m-2" role="status"></div>
      </div>
    </div>
  );
};

export default LoaderComponent;
