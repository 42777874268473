import { all, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  CREATE_USER,
  DELETE_USER,
  deleteUserSuccess,
  FETCH_USER,
  FETCH_USERS,
  fetchUserFail,
  fetchUsersFail,
  fetchUsersStarted,
  fetchUsersSuccess,
  fetchUserStarted,
  fetchUserSuccess,
  UPDATE_USER,
} from './actions';
import { addUser, editUser, getUser, getUsers, removeUser } from './api';

/**
 * Get All Users
 */
export function* watchFetchUsers(action: any) {
  yield put(fetchUsersStarted());
  try {
    const data = yield getUsers(action.payload);
    yield put(fetchUsersSuccess(data.data));
  } catch (e) {
    yield put(fetchUsersFail(e.message));
  }
}

export function* fetchUsersSaga() {
  yield takeEvery(FETCH_USERS, watchFetchUsers);
}

/**
 * Get All User
 */
export function* watchFetchUser(action: any) {
  yield put(fetchUserStarted());
  try {
    const data = yield getUser(action.payload);
    yield put(fetchUserSuccess(data.data));
  } catch (e) {
    yield put(fetchUserFail(e.message));
  }
}

export function* fetchUserSaga() {
  yield takeEvery(FETCH_USER, watchFetchUser);
}

/**
 * Create New User
 */
export function* watchCreateUser(action: any) {
  yield put(fetchUserStarted());
  try {
    const data = yield addUser(action.payload);
    yield put(fetchUserSuccess(data.data));
  } catch (e) {
    yield put(fetchUserFail(e.message));
  }
}

export function* createUserSaga() {
  yield takeLatest(CREATE_USER, watchCreateUser);
}

/**
 * Update User
 */
export function* watchUpdateUser(action: any) {
  yield put(fetchUserStarted());
  try {
    const data = yield editUser(action.payload);
    yield put(fetchUserSuccess(data.data));
  } catch (e) {
    yield put(fetchUserFail(e.message));
  }
}

export function* updateUserSaga() {
  yield takeEvery(UPDATE_USER, watchUpdateUser);
}

/**
 * Remove User
 */
export function* watchDeleteUser(action: any) {
  yield put(fetchUserStarted());
  try {
    const data = yield removeUser(action.payload);
    yield put(deleteUserSuccess(data.data));
  } catch (e) {
    yield put(fetchUserFail(e.message));
  }
}

export function* deleteUserSaga() {
  yield takeEvery(DELETE_USER, watchDeleteUser);
}

/**
 * User Saga
 */
function* userSaga(): any {
  yield all([
    fork(fetchUsersSaga),
    fork(fetchUserSaga),
    fork(createUserSaga),
    fork(updateUserSaga),
    fork(deleteUserSaga),
  ]);
}

export default userSaga;
