import AuthContext from 'context/AuthContext';
import RouterContext from 'context/RouterContext';
import { API } from 'helpers';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useAccess } from 'react-redux-permission';
import { withRouter } from 'react-router';
import { logout, logoutUser } from 'redux/auth';

const AppShellComponent = (props: any) => {
  const { location, history, match, children } = props;
  const [{ auth }, , removeCookie]: any = useCookies(['auth']);
  const dispatch = useDispatch();
  const { definePermission } = useAccess();
  const axiosInstance = API();

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    config => {
      if (auth && auth.accessToken) {
        // const auth = getLoggedInUser();
        if (auth && auth.accessToken) {
          config.headers['authorization'] = auth.accessToken;
        }
      }
      return config;
    },
    error => {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    error => {
      if (error && error.response && error.response.status === 401) {
        doLogout(true);
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject((error.response && error.response.data) || error);
    },
  );

  const doLogout = async (noApiCall = false) => {
    dispatch(logoutUser());
    if (!noApiCall && auth && auth.session) {
      await logout({
        sessionId: auth.session,
      }).catch(e => console.log(e));
    }
    removeCookie('auth', { path: '/' });
    localStorage.removeItem('tableFields');
    // history.replace('/login');
    window.location.replace('/login');
  };

  const definedPermissions = () => {
    if (
      auth &&
      auth.user &&
      auth.user.roleHasPermissions &&
      auth.user.roleHasPermissions.permissions &&
      auth.user.roleHasPermissions.permissions.length > 0
    ) {
      dispatch(definePermission(auth.user.roleHasPermissions.permissions));
    }
  };

  useEffect(() => {
    definedPermissions();
    return () => {};
  }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RouterContext.Provider
      value={{
        history,
        location,
        match,
      }}
    >
      <AuthContext.Provider
        value={{
          doLogout,
        }}
      >
        {children}
      </AuthContext.Provider>
    </RouterContext.Provider>
  );
};

export default withRouter(AppShellComponent);
