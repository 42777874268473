import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

const LinkComponent = (props: NavLinkProps) => {
  const { children, to, ...rest } = props;

  if (to && typeof to === 'string' && (to.indexOf('http://') !== -1 || to.indexOf('https://') !== -1)) {
    let href = to;
    if (href[0] === '/') {
      href = href.substring(1);
    }

    return (
      <a href={href} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <NavLink to={to} {...rest}>
      {children}
    </NavLink>
  );
};

export default LinkComponent;
