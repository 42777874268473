import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import customerSaga from './customer/saga';
import regionSaga from './region/saga';
import sizeSaga from './size/saga';
import plantSaga from './plant/saga';
import descriptionSaga from './description/saga';
import itemSaga from './item/saga';
import destinationSaga from './destination/saga';
import dashboardSaga from './dashboard/saga';
import userSaga from './user/saga';
import orderSaga from './order/saga';
import forecastSaga from './forecast/saga';
import documentSaga from './document/saga';

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    customerSaga(),
    userSaga(),
    dashboardSaga(),
    regionSaga(),
    sizeSaga(),
    descriptionSaga(),
    itemSaga(),
    orderSaga(),
    forecastSaga(),
    destinationSaga(),
    documentSaga(),
    plantSaga(),
  ]);
}
