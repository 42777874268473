import jwtDecode from 'jwt-decode';
import { User } from 'models/User.d';
import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
  const auth = getLoggedInUser();
  if (!auth) {
    return false;
  }
  const decoded = jwtDecode(auth.accessToken);
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    console.warn('access token expired');
    return false;
  } else {
    return true;
  }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser:
  | {
      accessToken?: string;
      session?: string;
      user?: User.Object;
    }
  | null
  | any = () => {
  const cookies = new Cookies();
  const auth: any = cookies.get('auth');
  return auth ? (typeof auth == 'object' ? auth : JSON.parse(auth)) : null;
};

export { isUserAuthenticated, getLoggedInUser };
