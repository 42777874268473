import PaginationComponent from 'components/Table/PaginationComponent';
import TableHeaderComponent, { TableHeaderColumnProps } from 'components/Table/TableHeaderComponent';
import React from 'react';
import { Show } from 'react-redux-permission';
import { DestinationsStateProps } from 'redux/destination';
import { DateTime } from 'luxon';
import { Destination } from 'models/Destination.d';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface DestinationListSceneProps {
  handleSearch?: (e: any) => void;
  onClickPrev?: (e: any) => void;
  onClickNext?: (e: any) => void;
  tableColumns?: TableHeaderColumnProps[];
  destinations?: Destination.Object;
  destinationsState?: DestinationsStateProps;
  apiParam?: any;
  handleDeleteDocument?: any;
  handleAddDestination?: any;
  handleEditDestination?: any;
}

const DestinationListScene = (props: DestinationListSceneProps) => {
  const {
    apiParam,
    handleSearch,
    tableColumns,
    destinations,
    destinationsState,
    onClickPrev,
    onClickNext,
    handleDeleteDocument,
    handleAddDestination,
    handleEditDestination,
  } = props;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <div className="card mt-4">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="header-title mb-0 mt-2">Destinations</h4>
                <div className="table-actions">
                  <div className="table-search">
                    <input
                      className="form-control"
                      type="text"
                      name="input-search"
                      placeholder="Search"
                      onChange={e => handleSearch(e)}
                    />
                  </div>
                  <Show when={['destination_create']}>
                    <button
                      type="button"
                      className="btn btn-sm btn-info"
                      onClick={() => {
                        handleAddDestination(true);
                      }}
                    >
                      <FontAwesomeIcon icon={['far', 'plus']} /> Add New
                    </button>
                  </Show>
                </div>
              </div>
              <div className="collapse pt-3 show">
                <div className="table-responsive">
                  <table className="table table-centered table-borderless mb-0">
                    <TableHeaderComponent className="thead-light" columns={tableColumns} />
                    <tbody>
                      {destinations &&
                        destinations.list &&
                        destinations.list.length > 0 &&
                        destinations.list.map((d, key) => (
                          <tr key={`destinations_${key}`}>
                            <td>{d.name}</td>
                            <td>{DateTime.fromISO(d.createdAt).toFormat('dd LLLL yyyy')}</td>
                            <td className="text-right">
                              <Show when={['destination_view']}>
                                <>
                                  <button
                                    className="btn btn-sm btn-success mr-1"
                                    onClick={() => handleEditDestination(true, d)}
                                    type="button"
                                  >
                                    <FontAwesomeIcon icon={['far', 'pencil']} />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => handleDeleteDocument(d)}
                                  >
                                    <FontAwesomeIcon icon={['far', 'trash']} />
                                  </button>
                                </>
                              </Show>
                            </td>
                          </tr>
                        ))}
                      {destinations && destinations.list && destinations.list.length === 0 && (
                        <tr>
                          <td colSpan={6} className="text-center">
                            <span>No record found</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {destinationsState && !destinationsState.isLoading && (
                  <PaginationComponent
                    {...{
                      count: destinations.count,
                      hasMany: destinations.hasMany,
                      limit: apiParam['$limit'],
                      skip: apiParam['$skip'],
                      total: destinations.total,
                      onClickPrev,
                      onClickNext,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DestinationListScene;
