import NoPermissionsComponent from 'components/NoPermissions/NoPermissionsComponent';
import RouterContext, { RouterContextModel } from 'context/RouterContext';
import { deepClone } from 'helpers';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Show } from 'react-redux-permission';
import { reduxForm, reset, SubmissionError } from 'redux-form';
import {
  getDocumentCategory,
  getDocumentSubCategory,
  uploadDocument,
  updateDocument,
  getDocument,
} from 'redux/document/api';
import UploadDocumentScene from './UploadDocumentScene';

export interface UploadDocumentContainerProps {
  match: any;
  initialize: (data?: any) => void;
  handleSubmit: (data: any) => () => void;
  pristine: boolean;
  submitting: boolean;
  error: string;
}

const UploadDocumentContainer = (props: UploadDocumentContainerProps) => {
  const {
    match: { params },
    initialize,
    handleSubmit,
    pristine,
    submitting,
    error,
  } = props;

  const documentId = params.id;

  const dispatch = useDispatch();
  const [pageType] = useState(documentId ? 'update' : 'add');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [documentCategories, setDocumentCategories] = useState(null);
  const [documentSubCategories, setDocumentSubCategories] = useState(null);
  const { history }: RouterContextModel = useContext(RouterContext);
  const formValues = useSelector((state: any) => state.form.uploadDoc && state.form.uploadDoc.values);
  const payload = new FormData();

  const onChangeHandler = async event => {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleResetFileUpload = event => {
    const { target = {} } = event || {};
    target.value = '';
  };

  const handleGetSubCategoryByCategory = async categoryId => {
    try {
      if (categoryId) {
        const res = await getDocumentSubCategory({ categoryId, params: { $limit: 49, $skip: 0 } });
        if (res && res.data && res.data.list && res.data.list.length > 0) {
          setDocumentSubCategories(res.data);
        } else {
          setDocumentSubCategories(null);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnChangeCategory = async event => {
    if (event && event.target && event.target.value) {
      handleGetSubCategoryByCategory(event.target.value);
    }
  };

  const onSubmit = async values => {
    try {
      if (values) {
        const payloadValues = deepClone(values);

        setIsLoading(true);
        if (selectedFile) {
          // Append form values to payload
          const ext = selectedFile.name
            .split('.')
            .pop()
            .toLowerCase();
          if (ext.indexOf('pdf') > -1) {
            // Append document file to payload
            payload.append('file', selectedFile);
          } else {
            setIsLoading(false);
            throw new SubmissionError({
              _error: 'File type not allowed',
            });
          }
        } else {
          // if (pageType === 'add' && values && values.category !== 'qualityMCCForms') {
          //   throw new SubmissionError({
          //     _error: 'Please Select File',
          //   });
          // }
        }

        if (pageType === 'update' && !documentSubCategories) {
          payloadValues.subCategory = null;
        }

        if (values && values.title && values.category === 'qualityMCCForms') {
          payloadValues.fileName = values.title;
        } else {
          delete payloadValues.fileName;
          delete payloadValues.filePath;
        }

        await Object.keys(payloadValues).forEach(key => {
          payload.append(key, payloadValues[key]);
        });

        let res: any = null;
        if (pageType === 'add') {
          res = await uploadDocument(payload);
        } else {
          res = await updateDocument({ payload, documentId });
        }
        if (res && res.data && res.data._id) {
          setIsLoading(false);
          setSelectedFile(null);
          dispatch(reset('uploadDoc'));
          history.push('/documents');
        } else {
          setIsLoading(false);
          throw new SubmissionError({
            _error: 'Something went wrong',
          });
        }
      }
    } catch (e) {
      setIsLoading(false);
      throw new SubmissionError({
        _error: e.message,
      });
    }
  };

  useEffect(() => {
    const getDocumentDetails = async () => {
      if (pageType === 'update' && documentId) {
        setIsLoading(true);
        const res = await getDocument(documentId);
        if (res && res.data && res.data._id) {
          setIsLoading(false);
          setDocumentDetails(res.data);
          const fillData: any = {
            title: res.data.title,
            category: res.data.category,
            subCategory: res.data.subCategory,
          };
          if (res.data.category === 'qualityMCCForms') {
            fillData.filePath = res.data.filePath;
          }
          initialize(fillData);
          if (res.data.category) {
            handleGetSubCategoryByCategory(res.data.category);
          }
        }
      }
    };
    getDocumentDetails();
    return () => {};
  }, [dispatch, pageType, documentId, initialize]);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const res = await getDocumentCategory({ params: { $limit: 49, $skip: 0 } });
        if (res && res.data && res.data.list && res.data.list.length > 0) {
          setDocumentCategories(res.data);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getCategories();
    return () => {};
  }, []);

  return (
    <UploadDocumentScene
      {...{
        form: {
          pristine,
          submitting,
        },
        error: error,
        onSave: handleSubmit(onSubmit),
        onChangeHandler,
        handleResetFileUpload,
        selectedFile,
        documentCategories,
        documentSubCategories,
        handleOnChangeCategory,
        formValues,
        isLoading,
        pageType,
        documentDetails,
      }}
    />
  );
};

const UploadDocumentContainerPermission = (props: any) => (
  <Show when={['documents_create', 'documents_update']} fallback={<NoPermissionsComponent />}>
    <UploadDocumentContainer {...props} />
  </Show>
);

const UploadDocumentForm: any = reduxForm({
  form: 'uploadDoc',
})(UploadDocumentContainerPermission);

export default UploadDocumentForm;
