import { API } from 'helpers';

export const getItems = async ({ params }) => {
  return await API().get('item', {
    params,
  });
};

export const getItemBySizeAndDescription = async ({ sizeId, descriptionId }) => {
  return await API().get(`item/${sizeId}/${descriptionId}`);
};
