export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_ORDERS_STARTED = 'FETCH_ORDERS_STARTED';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';
export const FETCH_ORDERS_RESET = 'FETCH_ORDERS_RESET';

export const UPDATE_ORDER = 'UPDATE_ORDER';

/**
 * Fetch Orders
 */

export const fetchOrders = payload => ({
  type: FETCH_ORDERS,
  payload,
});

export const fetchOrdersStarted = () => ({
  type: FETCH_ORDERS_STARTED,
  payload: {},
});

export const fetchOrdersSuccess = payload => ({
  type: FETCH_ORDERS_SUCCESS,
  payload,
});

export const fetchOrdersFail = payload => ({
  type: FETCH_ORDERS_FAIL,
  payload,
});

export const fetchOrdersReset = () => ({
  type: FETCH_ORDERS_RESET,
  payload: {},
});

export const updateOrderList = (data: any) => ({
  type: UPDATE_ORDER,
  payload: data,
});
