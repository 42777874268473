import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PaginationComponent from 'components/Table/PaginationComponent';
import TableHeaderComponent, { TableHeaderColumnProps } from 'components/Table/TableHeaderComponent';
import { Document } from 'models/Document.d';
import React from 'react';
import { Show } from 'react-redux-permission';
import { Link } from 'react-router-dom';
import { DocumentStateProps } from 'redux/document';
import { DateTime } from 'luxon';
import LinkComponent from 'components/LinkComponent';

export interface DocumentsSceneProps {
  handleSearch?: (e: any) => void;
  onClickPrev?: (e: any) => void;
  onClickNext?: (e: any) => void;
  tableColumns?: TableHeaderColumnProps[];
  documents?: Document.Object;
  documentsState?: DocumentStateProps;
  apiParam?: any;
  handleDeleteDocument?: any;
}

const DocumentsScene = (props: DocumentsSceneProps) => {
  const {
    apiParam,
    handleSearch,
    tableColumns,
    documents,
    documentsState,
    onClickPrev,
    onClickNext,
    handleDeleteDocument,
  } = props;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <div className="card mt-4">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h4 className="header-title mb-0 mt-2">Documents</h4>
                <div className="table-actions">
                  <div className="table-search">
                    <input
                      className="form-control"
                      type="text"
                      name="input-search"
                      placeholder="Search"
                      onChange={e => handleSearch(e)}
                    />
                  </div>
                  <Show when={['documents_create']}>
                    <Link to={'/documents/upload'} className="addButton">
                      <button type="button" className="btn btn-sm btn-info">
                        <FontAwesomeIcon icon={['fas', 'upload']} /> Upload
                      </button>
                    </Link>
                  </Show>
                </div>
              </div>
              <div className="collapse pt-3 show">
                <div className="table-responsive">
                  <table className="table table-centered table-borderless mb-0">
                    <TableHeaderComponent className="thead-light" columns={tableColumns} />
                    <tbody>
                      {documents &&
                        documents.list &&
                        documents.list.length > 0 &&
                        documents.list.map((d, key) => (
                          <tr key={`documents_${key}`}>
                            <td>{d.title}</td>
                            <td>{d.category && d.category.title}</td>
                            <td>{(d.subCategory && d.subCategory.title) || '-'}</td>
                            <td>{DateTime.fromISO(d.createdAt).toFormat('dd LLLL yyyy')}</td>
                            <td className="text-right">
                              <Show when={['documents_update']}>
                                <>
                                  <LinkComponent
                                    to={`/${d.filePath}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-sm btn-secondary mr-1"
                                  >
                                    <FontAwesomeIcon
                                      icon={[
                                        d.category && d.category.isExternal ? 'fas' : 'far',
                                        d.category && d.category.isExternal ? 'link' : 'download',
                                      ]}
                                    />
                                  </LinkComponent>
                                  <Link to={`/documents/update/${d._id}`} className="btn btn-sm btn-success mr-1">
                                    <FontAwesomeIcon icon={['far', 'pencil']} />
                                  </Link>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => handleDeleteDocument(d)}
                                  >
                                    <FontAwesomeIcon icon={['far', 'trash']} />
                                  </button>
                                </>
                              </Show>
                            </td>
                          </tr>
                        ))}
                      {documents && documents.list && documents.list.length === 0 && (
                        <tr>
                          <td colSpan={6} className="text-center">
                            <span>No record found</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {documentsState && !documentsState.isLoading && (
                  <PaginationComponent
                    {...{
                      count: documents.count,
                      hasMany: documents.hasMany,
                      limit: apiParam['$limit'],
                      skip: apiParam['$skip'],
                      total: documents.total,
                      onClickPrev,
                      onClickNext,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DocumentsScene;
