import { API } from 'helpers';

export const getDestinations = async ({ params }) => {
  return await API().get('destination', {
    params,
  });
};

export const uploadDestinations = async payload => {
  return await API().post('/destination', payload);
};

export const updateDestinations = async ({ destinationId, payload }) => {
  return await API().put(`/destination/${destinationId}`, payload);
};

export const deleteDestinations = async ({ destinationId }) => {
  return await API().delete(`/destination/${destinationId}`);
};
