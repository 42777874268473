import { deepClone } from 'helpers';
import { Order } from 'models/Order.d';
import {
  FETCH_ORDERS_FAIL,
  FETCH_ORDERS_RESET,
  FETCH_ORDERS_STARTED,
  FETCH_ORDERS_SUCCESS,
  UPDATE_ORDER,
} from './actions';

import _ from 'lodash';

/**
 * Orders Data
 */

export interface OrdersStateProps {
  data?: Order.Response;
  error?: string;
  isLoading?: boolean;
}

const initialOrdersState: OrdersStateProps = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialOrdersState = () => deepClone(initialOrdersState);

export const Orders = (state = getInitialOrdersState(), action: any) => {
  switch (action.type) {
    case FETCH_ORDERS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case UPDATE_ORDER:
      if (action.payload._id && state && state.data && state.data.list && state.data.list.length > 0) {
        const index = state.data.list.findIndex((order: Order.Object) => order._id === action.payload._id);
        if (index > -1) {
          state.data.list[index] = {
            ...state.data.list[index],
            ...action.payload,
          };
        }
      }
      return _.assignIn({}, state, {
        data: state.data,
        // error: null,
        // isLoading: false,
      });
    case FETCH_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_ORDERS_RESET:
      return getInitialOrdersState();
    default:
      return state;
  }
};
