export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_STARTED = 'FETCH_CUSTOMERS_STARTED';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAIL = 'FETCH_CUSTOMERS_FAIL';
export const FETCH_CUSTOMERS_RESET = 'FETCH_CUSTOMERS_RESET';

// TODO: Define customers payload
/**
 * Fetch Customers
 */

export const fetchCustomers = payload => ({
  type: FETCH_CUSTOMERS,
  payload,
});

export const fetchCustomersStarted = () => ({
  type: FETCH_CUSTOMERS_STARTED,
  payload: {},
});

export const fetchCustomersSuccess = payload => ({
  type: FETCH_CUSTOMERS_SUCCESS,
  payload,
});

export const fetchCustomersFail = payload => ({
  type: FETCH_CUSTOMERS_FAIL,
  payload,
});

export const fetchCustomersReset = () => ({
  type: FETCH_CUSTOMERS_RESET,
  payload: {},
});

/**
 * Fetch Customer
 */

export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_CUSTOMER_STARTED = 'FETCH_CUSTOMER_STARTED';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAIL = 'FETCH_CUSTOMER_FAIL';
export const FETCH_CUSTOMER_RESET = 'FETCH_CUSTOMER_RESET';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';

export const APPROVE_REJECT_CUSTOMER = 'APPROVE_REJECT_CUSTOMER';

// TODO: Define customer payload

export const fetchCustomer = payload => ({
  type: FETCH_CUSTOMER,
  payload,
});

export const approveRejectCustomer = payload => ({
  type: APPROVE_REJECT_CUSTOMER,
  payload,
});

export const createCustomer = payload => ({
  type: CREATE_CUSTOMER,
  payload,
});

export const updateCustomer = payload => ({
  type: UPDATE_CUSTOMER,
  payload,
});

export const deleteCustomer = payload => ({
  type: DELETE_CUSTOMER,
  payload,
});

export const deleteCustomerSuccess = payload => ({
  type: DELETE_CUSTOMER_SUCCESS,
  payload,
});

export const fetchCustomerStarted = () => ({
  type: FETCH_CUSTOMER_STARTED,
  payload: {},
});

export const fetchCustomerSuccess = payload => ({
  type: FETCH_CUSTOMER_SUCCESS,
  payload,
});

export const fetchCustomerFail = payload => ({
  type: FETCH_CUSTOMER_FAIL,
  payload,
});

export const fetchCustomerReset = () => ({
  type: FETCH_CUSTOMER_RESET,
  payload: {},
});

/**
 * Fetch Customer Users
 */

export const FETCH_CUSTOMER_USERS = 'FETCH_CUSTOMER_USERS';
export const FETCH_CUSTOMER_USERS_STARTED = 'FETCH_CUSTOMER_USERS_STARTED';
export const FETCH_CUSTOMER_USERS_SUCCESS = 'FETCH_CUSTOMER_USERS_SUCCESS';
export const FETCH_CUSTOMER_USERS_FAIL = 'FETCH_CUSTOMER_USERS_FAIL';
export const FETCH_CUSTOMER_USERS_RESET = 'FETCH_CUSTOMER_USERS_RESET';

export const fetchCustomerUsers = payload => ({
  type: FETCH_CUSTOMER_USERS,
  payload,
});

export const fetchCustomerUsersStarted = () => ({
  type: FETCH_CUSTOMER_USERS_STARTED,
  payload: {},
});

export const fetchCustomerUsersSuccess = payload => ({
  type: FETCH_CUSTOMER_USERS_SUCCESS,
  payload,
});

export const fetchCustomerUsersFail = payload => ({
  type: FETCH_CUSTOMER_USERS_FAIL,
  payload,
});

export const fetchCustomerUsersReset = () => ({
  type: FETCH_CUSTOMER_USERS_RESET,
  payload: {},
});

/**
 * Fetch Customer User
 */

export const FETCH_CUSTOMER_USER = 'FETCH_CUSTOMER_USER';
export const FETCH_CUSTOMER_USER_STARTED = 'FETCH_CUSTOMER_USER_STARTED';
export const FETCH_CUSTOMER_USER_SUCCESS = 'FETCH_CUSTOMER_USER_SUCCESS';
export const FETCH_CUSTOMER_USER_FAIL = 'FETCH_CUSTOMER_USER_FAIL';
export const FETCH_CUSTOMER_USER_RESET = 'FETCH_CUSTOMER_USER_RESET';

export const CREATE_CUSTOMER_USER = 'CREATE_CUSTOMER_USER';
export const UPDATE_CUSTOMER_USER = 'UPDATE_CUSTOMER_USER';

export const fetchCustomerUser = payload => ({
  type: FETCH_CUSTOMER_USER,
  payload,
});

export const createCustomerUser = payload => ({
  type: CREATE_CUSTOMER_USER,
  payload,
});

export const updateCustomerUser = payload => ({
  type: UPDATE_CUSTOMER_USER,
  payload,
});

export const fetchCustomerUserStarted = () => ({
  type: FETCH_CUSTOMER_USER_STARTED,
  payload: {},
});

export const fetchCustomerUserSuccess = payload => ({
  type: FETCH_CUSTOMER_USER_SUCCESS,
  payload,
});

export const fetchCustomerUserFail = payload => ({
  type: FETCH_CUSTOMER_USER_FAIL,
  payload,
});

export const fetchCustomerUserReset = () => ({
  type: FETCH_CUSTOMER_USER_RESET,
  payload: {},
});
