import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { ForgotPassword, Login, Register } from './auth/reducers';
import { Customer, Customers, CustomerUsers, CustomerUser } from './customer/reducers';
import { Tasks } from './dashboard/reducers';
import { Regions } from './region/reducers';
import { Sizes } from './size/reducers';
import { Plants } from './plant/reducers';
import { Descriptions } from './description/reducers';
import { Items } from './item/reducers';
import { Destinations } from './destination/reducers';
import { Orders } from './order/reducers';
import { Forecasts } from './forecast/reducers';
import { User, Users } from './user/reducers';
import { Documents, DocumentSubCategory } from './document/reducers';
import { permissionsReducer as permissions } from 'react-redux-permission';

export default combineReducers({
  Login,
  Register,
  ForgotPassword,
  Customer,
  Customers,
  CustomerUsers,
  CustomerUser,
  User,
  Users,
  Tasks,
  Regions,
  Sizes,
  Plants,
  Descriptions,
  Items,
  Destinations,
  Orders,
  Forecasts,
  permission: permissions,
  Documents,
  DocumentSubCategory,
  form: reduxFormReducer, // mounted under "form"
});
