export const FETCH_REGIONS = 'FETCH_REGIONS';
export const FETCH_REGIONS_STARTED = 'FETCH_REGIONS_STARTED';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_FAIL = 'FETCH_REGIONS_FAIL';
export const FETCH_REGIONS_RESET = 'FETCH_REGIONS_RESET';

/**
 * Fetch Regions
 */

export const fetchRegions = payload => ({
  type: FETCH_REGIONS,
  payload,
});

export const fetchRegionsStarted = () => ({
  type: FETCH_REGIONS_STARTED,
  payload: {},
});

export const fetchRegionsSuccess = payload => ({
  type: FETCH_REGIONS_SUCCESS,
  payload,
});

export const fetchRegionsFail = payload => ({
  type: FETCH_REGIONS_FAIL,
  payload,
});

export const fetchRegionsReset = () => ({
  type: FETCH_REGIONS_RESET,
  payload: {},
});
