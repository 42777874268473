export const FETCH_PLANTS = 'FETCH_PLANTS';
export const FETCH_PLANTS_STARTED = 'FETCH_PLANTS_STARTED';
export const FETCH_PLANTS_SUCCESS = 'FETCH_PLANTS_SUCCESS';
export const FETCH_PLANTS_FAIL = 'FETCH_PLANTS_FAIL';
export const FETCH_PLANTS_RESET = 'FETCH_PLANTS_RESET';

/**
 * Fetch PLANTS
 */

export const fetchPlants = payload => ({
  type: FETCH_PLANTS,
  payload,
});

export const fetchPlantsStarted = () => ({
  type: FETCH_PLANTS_STARTED,
  payload: {},
});

export const fetchPlantsSuccess = payload => ({
  type: FETCH_PLANTS_SUCCESS,
  payload,
});

export const fetchPlantsFail = payload => ({
  type: FETCH_PLANTS_FAIL,
  payload,
});

export const fetchPlantsReset = () => ({
  type: FETCH_PLANTS_RESET,
  payload: {},
});
