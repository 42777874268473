export const FETCH_FORECASTS = 'FETCH_FORECASTS';
export const FETCH_FORECASTS_STARTED = 'FETCH_FORECASTS_STARTED';
export const FETCH_FORECASTS_SUCCESS = 'FETCH_FORECASTS_SUCCESS';
export const FETCH_FORECASTS_FAIL = 'FETCH_FORECASTS_FAIL';
export const FETCH_FORECASTS_RESET = 'FETCH_FORECASTS_RESET';

export const UPDATE_FORECAST_VALUE = 'UPDATE_FORECAST_VALUE';

/**
 * Fetch Forecasts
 */

export const fetchForecasts = payload => ({
  type: FETCH_FORECASTS,
  payload,
});

export const fetchForecastsStarted = () => ({
  type: FETCH_FORECASTS_STARTED,
  payload: {},
});

export const fetchForecastsSuccess = payload => ({
  type: FETCH_FORECASTS_SUCCESS,
  payload,
});

export const fetchForecastsFail = payload => ({
  type: FETCH_FORECASTS_FAIL,
  payload,
});

export const fetchForecastsReset = () => ({
  type: FETCH_FORECASTS_RESET,
  payload: {},
});

export const updateForecastValue = (data: any) => ({
  type: UPDATE_FORECAST_VALUE,
  payload: data,
});
