import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  fetchDescriptionsStarted,
  fetchDescriptionsFail,
  fetchDescriptionsSuccess,
  FETCH_DESCRIPTIONS,
} from './actions';
import { getDescriptions } from './api';

/**
 * Get All Descriptions
 */
export function* watchFetchDescriptions(action: any) {
  yield put(fetchDescriptionsStarted());
  try {
    const data = yield getDescriptions(action.payload);
    yield put(fetchDescriptionsSuccess(data.data));
  } catch (e) {
    yield put(fetchDescriptionsFail(e.message));
  }
}

export function* fetchDescriptionSaga() {
  yield takeEvery(FETCH_DESCRIPTIONS, watchFetchDescriptions);
}

/**
 * Descriptions Saga
 */
function* descriptionSaga(): any {
  yield all([fork(fetchDescriptionSaga)]);
}

export default descriptionSaga;
