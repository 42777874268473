import { deepClone } from 'helpers';
import { Customer } from 'models/Customer.d';
import { FETCH_TASKS_FAIL, FETCH_TASKS_RESET, FETCH_TASKS_STARTED, FETCH_TASKS_SUCCESS } from './actions';

/**
 * Tasks Data
 */
const initialTasksState: {
  data?: Customer.Response;
  error?: string;
  isLoading?: boolean;
} = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialTasksState = () => deepClone(initialTasksState);

export const Tasks = (state = getInitialTasksState(), action: any) => {
  switch (action.type) {
    case FETCH_TASKS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_TASKS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_TASKS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_TASKS_RESET:
      return getInitialTasksState();
    default:
      return state;
  }
};
