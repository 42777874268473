import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  fetchDestinationsStarted,
  fetchDestinationsFail,
  fetchDestinationsSuccess,
  FETCH_DESTINATIONS,
} from './actions';
import { getDestinations } from './api';

/**
 * Get All Destinations
 */
export function* watchFetchDestinations(action: any) {
  yield put(fetchDestinationsStarted());
  try {
    const data = yield getDestinations(action.payload);
    yield put(fetchDestinationsSuccess(data.data));
  } catch (e) {
    yield put(fetchDestinationsFail(e.message));
  }
}

export function* fetchDestinationsSaga() {
  yield takeEvery(FETCH_DESTINATIONS, watchFetchDestinations);
}

/**
 * Destinations Saga
 */
function* destinationSaga(): any {
  yield all([fork(fetchDestinationsSaga)]);
}

export default destinationSaga;
