import { deepClone } from 'helpers';
import { Plant } from 'models/Plant.d';
import { FETCH_PLANTS_FAIL, FETCH_PLANTS_RESET, FETCH_PLANTS_STARTED, FETCH_PLANTS_SUCCESS } from './actions';

/**
 * Plants Data
 */
export interface PlantsStateProps {
  data?: Plant.Response;
  error?: string;
  isLoading?: boolean;
}

const initialPlantsState: PlantsStateProps = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialPlantsState = () => deepClone(initialPlantsState);

export const Plants = (state = getInitialPlantsState(), action: any) => {
  switch (action.type) {
    case FETCH_PLANTS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_PLANTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_PLANTS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_PLANTS_RESET:
      return getInitialPlantsState();
    default:
      return state;
  }
};
