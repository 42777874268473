import { library } from '@fortawesome/fontawesome-svg-core';
import { faChartLine, faChartNetwork, faShoppingCart } from '@fortawesome/pro-duotone-svg-icons';
import {
  faArrowAltRight,
  faBadgeCheck,
  faBoxes,
  faCheckCircle,
  faDownload,
  faEnvelopeOpenText,
  faEye,
  faFileChartPie,
  faFileContract,
  faFileSignature,
  faFileWord,
  faHandshake,
  faKeyboard,
  faPencil,
  faPencilRuler,
  faPlus,
  faQuestionCircle,
  faSack,
  faSort,
  faSortDown,
  faSortUp,
  faThumbsUp,
  faUpload,
  faUsdCircle,
  faUser,
  faUtensils,
  faClipboardListCheck,
  faStickyNote,
  faTimes,
  faPlusSquare,
  faTrash,
  faSave,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faStickyNote as fasStickyNote,
  faSave as fasSave,
  faCheck,
  faTruck,
  faBallotCheck,
  faQuestionCircle as fasQuestionCircle,
  faArrowsAlt,
  faExclamationTriangle,
  faFileUpload,
  faUpload as fasUpload,
  faLink as fasLink,
  faMapMarkedAlt,
} from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { configureStore } from './redux/store';
import * as serviceWorker from './serviceWorker';
import { PermissionProvider } from 'react-redux-permission';

library.add(
  faChartNetwork,
  faShoppingCart,
  faChartLine,
  faFileChartPie,
  faBadgeCheck,
  faUser,
  faBoxes,
  faPlus,
  faFileContract,
  faEnvelopeOpenText,
  faUtensils,
  faFileWord,
  faEye,
  faUpload,
  faDownload,
  faCheckCircle,
  faPencil,
  faArrowAltRight,
  faUsdCircle,
  faQuestionCircle,
  faHandshake,
  faKeyboard,
  faThumbsUp,
  faFileSignature,
  faSack,
  faPencilRuler,
  faSort,
  faSortUp,
  faSortDown,
  faClipboardListCheck,
  faStickyNote,
  faTimes,
  faPlusSquare,
  faTrash,
  fasStickyNote,
  faSave,
  fasSave,
  faCheck,
  faTruck,
  faBallotCheck,
  fasQuestionCircle,
  faArrowsAlt,
  faExclamationTriangle,
  faFileUpload,
  fasUpload,
  fasLink,
  faMapMarkedAlt,
);

// Redux Store
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PermissionProvider store={store} reducerKey="permission">
      <App />
    </PermissionProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
