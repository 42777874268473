export const FETCH_ITEMS = 'FETCH_ITEMS';
export const FETCH_ITEMS_STARTED = 'FETCH_ITEMS_STARTED';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAIL = 'FETCH_ITEMS_FAIL';
export const FETCH_ITEMS_RESET = 'FETCH_ITEMS_RESET';

/**
 * Fetch Items
 */

export const fetchItems = payload => ({
  type: FETCH_ITEMS,
  payload,
});

export const fetchItemsStarted = () => ({
  type: FETCH_ITEMS_STARTED,
  payload: {},
});

export const fetchItemsSuccess = payload => ({
  type: FETCH_ITEMS_SUCCESS,
  payload,
});

export const fetchItemsFail = payload => ({
  type: FETCH_ITEMS_FAIL,
  payload,
});

export const fetchItemsReset = () => ({
  type: FETCH_ITEMS_RESET,
  payload: {},
});
