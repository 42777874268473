import UploadDocumentContainer from 'pages/UploadDocument/UploadDocumentContainer';
import DocumentsContainer from 'pages/Documents/DocumentsContainer';
import React from 'react';
import { useCookies } from 'react-cookie';
import DestinationListContainer from 'pages/Destination/DestinationListContainer';
import { Redirect, Route } from 'react-router-dom';

import { isUserAuthenticated } from './helpers/authUtils';

// lazy load all the views
const DashboardContainer = React.lazy(() => import('./pages/dashboard'));
const CustomerListContainer = React.lazy(() => import('./pages/CustomerList/CustomerListContainer'));
const OrderListContainer = React.lazy(() => import('./pages/OrderList/OrderListContainer'));
const CustomerContainer = React.lazy(() => import('./pages/Customer/CustomerContainer'));
const UserListContainer = React.lazy(() => import('./pages/UserList/UserListContainer'));
const UserAddEditContainer = React.lazy(() => import('./pages/UserAddEdit/UserAddEditContainer'));
const CustomerUserAddEditContainer = React.lazy(() =>
  import('./pages/CustomerUserAddEdit/CustomerUserAddEditContainer'),
);

// auth
const LoginScene = React.lazy(() => import('./pages/auth/LoginScene'));
const ForgetPassword = React.lazy(() => import('./pages/account/ForgetPassword'));
const SetPasswordContainer = React.lazy(() => import('./pages/SetPassword/SetPasswordContainer'));
const RegisterContainer = React.lazy(() => import('./pages/account/RegisterContainer'));
const ConfirmAccount = React.lazy(() => import('./pages/account/Confirm'));
const NdaAgreementScene = React.lazy(() => import('./pages/NdaAgreement/NdaAgreementScene'));
const DunnageScene = React.lazy(() => import('./pages/Dunnage'));
const SpecificationsScene = React.lazy(() => import('./pages/Specifications'));
const FoodSafetyScene = React.lazy(() => import('./pages/FoodSafety'));
const LogisticsScene = React.lazy(() => import('./pages/Logistics'));
const QualityFormsScene = React.lazy(() => import('./pages/QualityForms'));
const UserGuideScene = React.lazy(() => import('./pages/UserGuide'));

const HomeComponent: any = () => {
  const [{ auth }]: any = useCookies(['auth']);

  // if (
  //   auth &&
  //   auth.user.role === 'contact' &&
  //   auth.user.primaryContact &&
  //   auth.user.primaryContact.ndaStatus === 'pending'
  // ) {
  //   return <Redirect to={{ pathname: '/nda-agreement' }} />;
  // }

  if (auth && auth.user && auth.user.role === 'sales') {
    return <Redirect to="/customer/list" />;
  } else if (auth && auth.user && auth.user.role === 'contact') {
    if (
      auth.user.isPriceUpdated === true &&
      auth.user.isPriceApproved === true &&
      auth.user.workFlowStatus === 'entered_in_sap'
    ) {
      return <Redirect to="/order/list" />;
    } else {
      return <Redirect to={`/customer/edit/${auth.user.customerId}`} />;
    }
  } else if (
    auth &&
    auth.user &&
    ['admin', 'contact', 'customer_facing_manager', 'logistic_coordinator'].includes(auth.user.role)
  ) {
    return <Redirect to="/order/list" />;
  }

  return <Redirect to="/dashboard" />;
};

// handle auth and authorization

// const PrivateRoute = (props: any) => {
//   const { component: Component, roles, location, ...rest } = props;
//   let isAuthTokenValid = isUserAuthenticated();
//   const [{ auth }]: any = useCookies(['auth']);

//   useEffect(() => {
//     isAuthTokenValid = isUserAuthenticated();
//     return () => { };
//   }, [auth, isUserAuthenticated]);

//   if (!isAuthTokenValid) {
//     // not logged in so redirect to login page with the return url
//     return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
//   }

//   // const auth = getLoggedInUser();
//   // check if route is restricted by role
//   if (roles && roles.indexOf(auth.user.role) === -1) {
//     // role not authorized so redirect to home page
//     return <Redirect to={{ pathname: '/' }} />;
//   }

//   return (
//     <Route
//       {...rest}
//       render={props => {
//         // authorized so return component
//         return <Component {...props} />;
//       }}
//     />
//   );
// };

const PrivateRoute = ({ component: Component, /* roles, */ ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const isAuthTokenValid = isUserAuthenticated();
      if (!isAuthTokenValid) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
      }

      // const auth = getLoggedInUser();
      // check if route is restricted by role
      // if (roles && roles.indexOf(auth.user.role) === -1) {
      //   // role not authorized so redirect to home page
      //   return <Redirect to={{ pathname: '/' }} />;
      // }

      // authorized so return component
      return <Component {...props} />;
    }}
  />
);

const routes = [
  // auth and account
  { path: '/login', exact: true, name: 'LoginScene', component: LoginScene, route: Route },
  { path: '/forget-password', exact: true, name: 'Forget Password', component: ForgetPassword, route: Route },
  { path: '/set-password', exact: true, name: 'Set Password', component: SetPasswordContainer, route: Route },
  { path: '/reset-password', exact: true, name: 'Reset Password', component: SetPasswordContainer, route: Route },
  { path: '/register', exact: true, name: 'Register', component: RegisterContainer, route: Route },
  { path: '/confirm', exact: true, name: 'Confirm', component: ConfirmAccount, route: Route },

  // other pages
  {
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    component: DashboardContainer,
    route: PrivateRoute,
  },
  {
    path: '/order/list',
    exact: true,
    name: 'Orders',
    component: OrderListContainer,
    route: PrivateRoute,
  },
  {
    path: '/documents',
    exact: true,
    name: 'Documents',
    component: DocumentsContainer,
    route: PrivateRoute,
  },
  {
    path: '/destination',
    exact: true,
    name: 'Destination',
    component: DestinationListContainer,
    route: PrivateRoute,
  },
  {
    path: '/documents/upload',
    exact: true,
    name: 'Upload Document',
    component: UploadDocumentContainer,
    route: PrivateRoute,
  },
  {
    path: '/documents/update/:id',
    exact: true,
    name: 'Update Document',
    component: UploadDocumentContainer,
    route: PrivateRoute,
  },
  {
    path: '/dunnage',
    exact: true,
    name: 'Dunnage',
    component: DunnageScene,
    route: PrivateRoute,
  },
  {
    path: '/specifications',
    exact: true,
    name: 'Specifications',
    component: SpecificationsScene,
    route: PrivateRoute,
  },
  {
    path: '/food-safety',
    exact: true,
    name: 'FoodSafety',
    component: FoodSafetyScene,
    route: PrivateRoute,
  },
  {
    path: '/logistics',
    exact: true,
    name: 'Logistics',
    component: LogisticsScene,
    route: PrivateRoute,
  },
  {
    path: '/quality-forms',
    exact: true,
    name: 'Quality MCC Forms',
    component: QualityFormsScene,
    route: PrivateRoute,
  },
  {
    path: '/user-guide',
    exact: true,
    name: 'User Guide',
    component: UserGuideScene,
    route: PrivateRoute,
  },
  // Customer Pages
  {
    path: '/customer/list',
    exact: true,
    name: 'CustomerList',
    component: CustomerListContainer,
    route: PrivateRoute,
  },
  {
    path: '/customer/add',
    exact: true,
    name: 'CustomerAdd',
    component: CustomerContainer,
    route: PrivateRoute,
  },
  {
    path: '/customer/edit/:id',
    exact: true,
    name: 'CustomerEdit',
    component: CustomerContainer,
    route: PrivateRoute,
  },
  {
    path: '/customer/:customerId/user/add',
    exact: true,
    name: 'CustomerUserAdd',
    component: CustomerUserAddEditContainer,
    route: PrivateRoute,
  },
  {
    path: '/customer/:customerId/user/edit/:id',
    exact: true,
    name: 'CustomerUserEdit',
    component: CustomerUserAddEditContainer,
    route: PrivateRoute,
  },
  // User Pages
  {
    path: '/user/list',
    exact: true,
    name: 'UserList',
    component: UserListContainer,
    route: PrivateRoute,
  },
  {
    path: '/user/add',
    exact: true,
    name: 'UserAdd',
    component: UserAddEditContainer,
    route: PrivateRoute,
  },
  {
    path: '/user/edit/:id',
    exact: true,
    name: 'UserEdit',
    component: UserAddEditContainer,
    route: PrivateRoute,
  },
  {
    path: '/',
    exact: true,
    component: HomeComponent,
    route: PrivateRoute,
  },
  {
    path: '/nda-agreement',
    exact: true,
    name: 'NDA Agreement',
    component: NdaAgreementScene,
    route: PrivateRoute,
  },
];

export { routes, PrivateRoute };
