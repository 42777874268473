import { deepClone } from 'helpers';
import { Size } from 'models/Size.d';
import { FETCH_SIZES_FAIL, FETCH_SIZES_RESET, FETCH_SIZES_STARTED, FETCH_SIZES_SUCCESS } from './actions';

/**
 * Sizes Data
 */
export interface SizesStateProps {
  data?: Size.Response;
  error?: string;
  isLoading?: boolean;
}

const initialSizesState: SizesStateProps = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialSizesState = () => deepClone(initialSizesState);

export const Sizes = (state = getInitialSizesState(), action: any) => {
  switch (action.type) {
    case FETCH_SIZES_STARTED:
      return { ...state, isLoading: true };
    case FETCH_SIZES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_SIZES_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_SIZES_RESET:
      return getInitialSizesState();
    default:
      return state;
  }
};
