import { all, fork, put, takeEvery } from 'redux-saga/effects';
import {
  FETCH_DOCUMENTS,
  fetchDocumentsFail,
  fetchDocumentsStarted,
  fetchDocumentsSuccess,
  FETCH_DOCUMENT_SUBCATEGORY,
  fetchDocumentSubCategoryFail,
  fetchDocumentSubCategoryStarted,
  fetchDocumentSubCategorySuccess,
} from './actions';
import { getDocumentSubCategory, getAllDocument } from './api';

export function* watchFetchDocuments(action: any) {
  yield put(fetchDocumentsStarted());
  try {
    const data = yield getAllDocument(action.payload);
    yield put(fetchDocumentsSuccess(data.data));
  } catch (e) {
    yield put(fetchDocumentsFail(e.message));
  }
}

export function* watchFetchDocumentsCategories(action: any) {
  yield put(fetchDocumentSubCategoryStarted());
  try {
    const data = yield getDocumentSubCategory(action.payload);
    yield put(fetchDocumentSubCategorySuccess(data.data));
  } catch (e) {
    yield put(fetchDocumentSubCategoryFail(e.message));
  }
}

export function* fetchDocumentsSaga() {
  yield takeEvery(FETCH_DOCUMENTS, watchFetchDocuments);
}
export function* fetchDocumentsCategorySaga() {
  yield takeEvery(FETCH_DOCUMENT_SUBCATEGORY, watchFetchDocumentsCategories);
}
function* documentSaga(): any {
  yield all([fork(fetchDocumentsSaga), fork(fetchDocumentsCategorySaga)]);
}

export default documentSaga;
