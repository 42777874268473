import React from 'react';
import InputMask from 'react-input-mask';

export interface RenderErrorComponentProps {
  error: string[] | string;
  htmlFor?: string;
  handleHtmlForElement?: any;
}

export const RenderErrorComponent: any = (props: RenderErrorComponentProps) => {
  const { error } = props;

  if (typeof error === 'string') {
    return error;
  }
};

export const InputFieldOnly: any = ({
  input,
  label,
  placeholder,
  mask,
  maskChar,
  defaultValue,
  className = 'form-control',
  id,
  type,
  autoCapitalize,
  autoComplete,
  meta: { active, touched, error, warning },
  showError = true,
  disabled = false,
}: any) => {
  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      {/* REF: https://codesandbox.io/s/inputelement-with-redux-form-ir8fr */}
      {mask && (
        <InputMask
          className={`${touched && !active && error ? 'is-invalid' : ''} ${
            (touched || input.value) && !error ? 'is-valid' : ''
          } ${className}`}
          {...{
            ...input,
            defaultValue,
            id,
            type,
            autoCapitalize,
            autoComplete,
            placeholder,
            mask,
            maskChar,
            disabled,
          }}
        />
      )}
      {!mask && (
        <input
          className={`${touched && !active && error ? 'is-invalid' : ''} ${
            (touched || input.value) && !error ? 'is-valid' : ''
          } ${className}`}
          {...{
            ...input,
            defaultValue,
            id,
            type,
            autoCapitalize,
            autoComplete,
            placeholder,
            disabled,
          }}
        />
      )}
      {showError &&
        touched &&
        ((error && (
          <span className="text-danger">
            <RenderErrorComponent error={error} />
          </span>
        )) ||
          (warning && (
            <span className="text-warning">
              <RenderErrorComponent error={warning} />
            </span>
          )))}
    </>
  );
};

export const SelectFieldOnly: any = ({
  input,
  children,
  label,
  defaultValue,
  className = 'form-control',
  id,
  meta: { active, touched, error, warning },
  showError = true,
  disabled = false,
}: any) => {
  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      <select
        className={`${touched && !active && error ? 'is-invalid' : ''} ${
          touched && !error ? 'is-valid' : ''
        } ${className}`}
        {...{
          ...input,
          defaultValue,
          id,
          disabled,
        }}
      >
        {children}
      </select>
      {showError &&
        touched &&
        ((error && (
          <span className="text-danger">
            <RenderErrorComponent error={error} />
          </span>
        )) ||
          (warning && (
            <span className="text-warning">
              <RenderErrorComponent error={warning} />
            </span>
          )))}
    </>
  );
};

export const TextAreaOnly: any = ({
  input,
  placeholder,
  label,
  defaultValue,
  className = 'form-control',
  id,
  type,
  autoCapitalize,
  autoComplete,
  minLength,
  maxLength,
  meta: { active, touched, error, warning },
  showError = true,
  rows = 2,
  disabled = false,
  readOnly = false,
}: any) => {
  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      <textarea
        className={`${touched && !active && error ? 'is-invalid' : ''} ${
          (touched || input.value) && !error ? 'is-valid' : ''
        } ${className}`}
        {...{
          ...input,
          defaultValue,
          id,
          type,
          autoCapitalize,
          autoComplete,
          placeholder,
          rows,
          minLength,
          maxLength,
          disabled,
          readOnly,
        }}
      />
      {showError &&
        touched &&
        ((error && (
          <span className="text-danger">
            <RenderErrorComponent error={error} />
          </span>
        )) ||
          (warning && (
            <span className="text-warning">
              <RenderErrorComponent error={warning} />
            </span>
          )))}
    </>
  );
};
