import { deepClone } from 'helpers';
import { Customer as CustomerRef } from 'models/Customer.d';
import { User as UserRef } from 'models/User.d';

import {
  DELETE_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAIL,
  FETCH_CUSTOMER_RESET,
  FETCH_CUSTOMER_STARTED,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMERS_FAIL,
  FETCH_CUSTOMERS_RESET,
  FETCH_CUSTOMERS_STARTED,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMER_USERS_STARTED,
  FETCH_CUSTOMER_USERS_SUCCESS,
  FETCH_CUSTOMER_USERS_FAIL,
  FETCH_CUSTOMER_USERS_RESET,
  FETCH_CUSTOMER_USER_STARTED,
  FETCH_CUSTOMER_USER_SUCCESS,
  FETCH_CUSTOMER_USER_FAIL,
  FETCH_CUSTOMER_USER_RESET,
} from './actions';

/**
 * Customers Data
 */

const initialCustomersState: {
  data?: CustomerRef.Response;
  error?: string;
  isLoading?: boolean;
} = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialCustomersState = () => deepClone(initialCustomersState);

export const Customers = (state = getInitialCustomersState(), action: any) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_CUSTOMERS_RESET:
      return getInitialCustomersState();
    default:
      return state;
  }
};

/**
 * Customer Data
 */

export interface CustomerStateProps {
  data?: CustomerRef.Object;
  error?: string;
  isLoading?: boolean;
  deleted?: boolean;
}
const initialCustomerState: CustomerStateProps = {
  data: {},
  error: null,
  isLoading: false,
  deleted: false,
};

export const getInitialCustomerState = () => deepClone(initialCustomerState);

export const Customer = (state = getInitialCustomerState(), action: any) => {
  switch (action.type) {
    case FETCH_CUSTOMER_STARTED:
      return { ...state, isLoading: true };
    case FETCH_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
        deleted: false,
      };
    }
    case DELETE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
        deleted: true,
      };
    }
    case FETCH_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        deleted: false,
      };
    case FETCH_CUSTOMER_RESET:
      return getInitialCustomerState();
    default:
      return state;
  }
};

/**
 * Customer Users Data
 */

export interface CustomerUsersStateProps {
  data?: UserRef.Response;
  error?: string;
  isLoading?: boolean;
}

const initialCustomerUsersState: CustomerUsersStateProps = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialCustomerUsersState = () => deepClone(initialCustomerUsersState);

export const CustomerUsers = (state = getInitialCustomerUsersState(), action: any) => {
  switch (action.type) {
    case FETCH_CUSTOMER_USERS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_CUSTOMER_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_CUSTOMER_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_CUSTOMER_USERS_RESET:
      return getInitialCustomerUsersState();
    default:
      return state;
  }
};

/**
 * Customer User Data
 */

export interface CustomerUserStateProps {
  data?: UserRef.Object;
  error?: string;
  isLoading?: boolean;
  deleted?: boolean;
}
const initialCustomerUserState: CustomerUserStateProps = {
  data: {},
  error: null,
  isLoading: false,
  deleted: false,
};

export const getInitialCustomerUserState = () => deepClone(initialCustomerUserState);

export const CustomerUser = (state = getInitialCustomerUserState(), action: any) => {
  switch (action.type) {
    case FETCH_CUSTOMER_USER_STARTED:
      return { ...state, isLoading: true };
    case FETCH_CUSTOMER_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
        deleted: false,
      };
    }
    case FETCH_CUSTOMER_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        deleted: false,
      };
    case FETCH_CUSTOMER_USER_RESET:
      return getInitialCustomerUserState();
    default:
      return state;
  }
};
