import { deepClone } from 'helpers';
import { Document as DocumentRef } from 'models/Document.d';

import {
  FETCH_DOCUMENTS_RESET,
  FETCH_DOCUMENTS_STARTED,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAIL,
  FETCH_DOCUMENT_SUBCATEGORY_RESET,
  FETCH_DOCUMENT_SUBCATEGORY_STARTED,
  FETCH_DOCUMENT_SUBCATEGORY_FAIL,
  FETCH_DOCUMENT_SUBCATEGORY_SUCCESS,
} from './actions';

export interface DocumentStateProps {
  data?: DocumentRef.Response;
  error?: string;
  isLoading?: boolean;
}

const initialDocumentState: DocumentStateProps = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialDocumentState = () => deepClone(initialDocumentState);

export const Documents = (state = getInitialDocumentState(), action: any) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_DOCUMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_DOCUMENTS_RESET:
      return getInitialDocumentState();
    default:
      return state;
  }
};

export interface DocumentSubCategoryStateProps {
  data?: DocumentRef.Response;
  error?: string;
  isLoading?: boolean;
}

const initialDocumentSubCategoryState: DocumentSubCategoryStateProps = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialDocumentSubCategoryState = () => deepClone(initialDocumentSubCategoryState);

export const DocumentSubCategory = (state = getInitialDocumentSubCategoryState(), action: any) => {
  switch (action.type) {
    case FETCH_DOCUMENT_SUBCATEGORY_STARTED:
      return { ...state, isLoading: true };
    case FETCH_DOCUMENT_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_DOCUMENT_SUBCATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_DOCUMENT_SUBCATEGORY_RESET:
      return getInitialDocumentSubCategoryState();
    default:
      return state;
  }
};
