import { deepClone } from 'helpers';
import { Region } from 'models/Region.d';
import { FETCH_REGIONS_FAIL, FETCH_REGIONS_RESET, FETCH_REGIONS_STARTED, FETCH_REGIONS_SUCCESS } from './actions';

/**
 * Regions Data
 */

export interface RegionsStateProps {
  data?: Region.Response;
  error?: string;
  isLoading?: boolean;
}

const initialRegionsState: RegionsStateProps = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialRegionsState = () => deepClone(initialRegionsState);

export const Regions = (state = getInitialRegionsState(), action: any) => {
  switch (action.type) {
    case FETCH_REGIONS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_REGIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_REGIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_REGIONS_RESET:
      return getInitialRegionsState();
    default:
      return state;
  }
};
