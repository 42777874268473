import { API } from 'helpers';

export const getCustomers = async ({ params }) => {
  return await API().get('customer', {
    params,
  });
};

export const getAllCustomers = async () => {
  return await API().get(`customer/allCustomers`);
};

export const getCustomer = async ({ customerId, params }) => {
  return await API().get(`customer/${customerId}`, {
    params,
  });
};

export const addCustomer = async ({ payload }) => {
  return await API().post(`customer`, payload);
};

export const editCustomer = async ({ customerId, payload }) => {
  return await API().put(`customer/${customerId}`, payload);
};

export const removeCustomer = async customerId => {
  return await API().delete(`customer/${customerId}`);
};

export const approveRejectCustomerAPI = async ({ customerId, status }) => {
  return await API().post(`customer/${customerId}/${status}`);
};

// Customer Users API

export const getCustomerUsers = async ({ params }) => {
  return await API().get('customer/users', {
    params,
  });
};

export const getCustomerUser = async userId => {
  return await API().get(`customer/user/${userId}`);
};

export const addCustomerUser = async ({ payload }) => {
  return await API().post(`customer/user`, payload);
};

export const editCustomerUser = async ({ userId, payload }) => {
  return await API().put(`customer/user/${userId}`, payload);
};

export const getCustomerDestinations = async ({ customerId, params }) => {
  return await API().get(`customer/${customerId}/getDestinations`, {
    params,
  });
};

export const editCustomerDestinations = async ({ customerId, payload }) => {
  return await API().put(`customer/${customerId}/updateDestinations`, payload);
};
