import NoPermissionsImg from 'assets/images/no-permission.svg';
import React from 'react';

import './styles.scss';

export interface NoPermissionsComponentProps {
  message?: string;
}

const NoPermissionsComponent = (props: NoPermissionsComponentProps) => {
  let { message } = props;
  if (!message) {
    message = "You don't have permission to access this page.";
  }
  return (
    <div className={`flex-1 w-100 noFormsContainer`}>
      <div className="flex-1 d-flex align-items-center justify-content-center flex-column">
        <div className="imageContainer">
          <img src={NoPermissionsImg} width={180} alt="No Permission" />
        </div>
        <h4>{message}</h4>
        <p>Please contact to your account administrator </p>
      </div>
    </div>
  );
};

export default NoPermissionsComponent;
