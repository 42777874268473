import states from './states';

export const config = {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT || 'http://mcc-api.moonrocktech.com/v1/',
  ENDPOINT: process.env.REACT_APP_ENDPOINT || 'http://mcc-api.moonrocktech.com',
  CURRENCY: [{ key: 'usd', value: 'USD' }],
  STATES: states,
  USER_ROLES: {
    admin: 'Admin',
    sales: 'Sales',
    contact: 'Contact',
    finance_team: 'Finance Team',
    finance_vp: 'Finance VP',
    sap_team: 'GCC Team',
    customer_facing_manager: 'Customer Facing Manager',
    logistic_coordinator: 'Logistic Coordinator',
  },
  CUSTOMER_WORKFLOW_STATUS: {
    access_requested: 'Access Requested',
    access_accepted: 'Access Accepted',
    nda_signed: 'NDA Signed',
    price_updated: 'Price Updated',
    price_approved: 'Price Approved',
    entered_in_sap: 'Entered in GCC',
  },
  CUSTOMER_TASKS: {
    finance_team: 'Need to update price updates',
    finance_vp: 'Need to approve price updates',
    sap_team: 'Enter customer in GCC',
  },
};
