import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchOrdersStarted, fetchOrdersFail, fetchOrdersSuccess, FETCH_ORDERS } from './actions';
import { getOrders } from './api';

/**
 * Get All Orders
 */
export function* watchFetchOrders(action: any) {
  yield put(fetchOrdersStarted());
  try {
    const data = yield getOrders(action.payload);
    yield put(fetchOrdersSuccess(data.data));
  } catch (e) {
    yield put(fetchOrdersFail(e.message));
  }
}

export function* fetchOrdersSaga() {
  yield takeEvery(FETCH_ORDERS, watchFetchOrders);
}

/**
 * Orders Saga
 */
function* orderSaga(): any {
  yield all([fork(fetchOrdersSaga)]);
}

export default orderSaga;
