// Themes
import './assets/scss/DefaultTheme.scss';
import 'react-toastify/dist/ReactToastify.css';

import AppShellComponent from 'components/AppShellComponent';
import React, { Component, Suspense } from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { isUserAuthenticated } from './helpers/authUtils';
import { routes } from './routes';

// setup fake backend
// import { configureFakeBackend } from './helpers';
// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
const NonAuthLayout = Loadable({
  loader: () => import('./components/NonAuthLayout'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const MainLayout = Loadable({
  loader: () => import('./components/MainLayout'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

// configure fake backend
// configureFakeBackend();

/**
 * Exports the component with layout wrapped to it
 * @param {} WrappedComponent
 */
const withLayout = WrappedComponent => {
  const HOC = class extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return connect()(HOC);
};

/**
 * Main app component
 */
const App = () => {
  /**
   * Returns the layout component based on different properties
   * @param {*} props
   */
  const getLayout = route => {
    if (route && route.path === '/nda-agreement') {
      return NonAuthLayout;
    }
    return isUserAuthenticated() ? MainLayout : NonAuthLayout;
  };

  return (
    // rendering the router with layout
    <BrowserRouter>
      <React.Fragment>
        <AppShellComponent>
          {/* */}
          {routes.map((route, index) => {
            return (
              <route.route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withLayout(props => {
                  const Layout = getLayout(route);
                  return (
                    <Suspense fallback={loading()}>
                      <Layout {...props}>
                        <route.component {...props} />
                      </Layout>
                    </Suspense>
                  );
                })}
              />
            );
          })}
        </AppShellComponent>
        <ToastContainer />
      </React.Fragment>
    </BrowserRouter>
  );
};

export default App;
