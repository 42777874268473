export const FETCH_TASKS = 'FETCH_TASKS';
export const FETCH_TASKS_STARTED = 'FETCH_TASKS_STARTED';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAIL = 'FETCH_TASKS_FAIL';
export const FETCH_TASKS_RESET = 'FETCH_TASKS_RESET';

/**
 * Fetch Tasks
 */

export const fetchTasks = payload => ({
  type: FETCH_TASKS,
  payload,
});

export const fetchTasksStarted = () => ({
  type: FETCH_TASKS_STARTED,
  payload: {},
});

export const fetchTasksSuccess = payload => ({
  type: FETCH_TASKS_SUCCESS,
  payload,
});

export const fetchTasksFail = payload => ({
  type: FETCH_TASKS_FAIL,
  payload,
});

export const fetchTasksReset = () => ({
  type: FETCH_TASKS_RESET,
  payload: {},
});
