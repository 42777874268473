import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchSizesStarted, fetchSizesFail, fetchSizesSuccess, FETCH_SIZES } from './actions';
import { getSizes } from './api';

/**
 * Get All Sizes
 */
export function* watchFetchSizes(action: any) {
  yield put(fetchSizesStarted());
  try {
    const data = yield getSizes(action.payload);
    yield put(fetchSizesSuccess(data.data));
  } catch (e) {
    yield put(fetchSizesFail(e.message));
  }
}

export function* fetchSizesSaga() {
  yield takeEvery(FETCH_SIZES, watchFetchSizes);
}

/**
 * Sizes Saga
 */
function* sizeSaga(): any {
  yield all([fork(fetchSizesSaga)]);
}

export default sizeSaga;
