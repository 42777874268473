import { API } from 'helpers';

export const login = async payload => {
  return await API().post('login', payload);
};

export const register = async payload => {
  return await API().post('register', payload);
};

export const forgotPassword = async payload => {
  return await API().post('forgotPassword', payload);
};

export const logout = async payload => {
  return await API().post('logout', payload);
};

export const setPassword = async payload => {
  return await API().post('setPassword', payload);
};

export const resetPassword = async payload => {
  return await API().post('resetPassword', payload);
};

export const ndaAgreement = async payload => {
  return await API().post('customer/ndaAgreement', payload);
};
