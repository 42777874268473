import React from 'react';
import { Button, Col, Row } from 'reactstrap';

export interface PaginationComponentProps {
  limit: number;
  skip: number;
  count: number;
  total: number;
  hasMany: boolean;
  isLoading?: boolean;
  onClickNext?: (e: any) => void;
  onClickPrev?: (e: any) => void;
  nextLabel?: string;
  prevLabel?: string;
}

const PaginationComponent = (props: PaginationComponentProps) => {
  const {
    isLoading,
    count,
    hasMany,
    limit,
    onClickNext = () => {},
    onClickPrev = () => {},
    skip,
    total,
    nextLabel = 'Next',
    prevLabel = 'Previous',
  } = props;

  return (
    <Row className="align-items-center">
      <Col>
        {total >= limit ? `${skip + 1} - ${skip + count} from ` : ''}
        {total} {total > 1 ? 'results' : 'result'}
      </Col>
      <Col className="text-right">
        <Button
          disabled={!(!isLoading && skip > 0)}
          className="mr-1"
          onClick={e => {
            if (!isLoading && skip > 0) {
              onClickPrev(e);
            }
          }}
        >
          {prevLabel}
        </Button>
        <Button
          disabled={!(!isLoading && hasMany)}
          onClick={e => {
            if (!isLoading && hasMany) {
              onClickNext(e);
            }
          }}
        >
          {nextLabel}
        </Button>
      </Col>
    </Row>
  );
};

export default PaginationComponent;
