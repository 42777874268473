import { deepClone } from 'helpers';
import { Destination } from 'models/Destination.d';
import {
  FETCH_DESTINATIONS_FAIL,
  FETCH_DESTINATIONS_RESET,
  FETCH_DESTINATIONS_STARTED,
  FETCH_DESTINATIONS_SUCCESS,
} from './actions';

/**
 * Destinations Data
 */

export interface DestinationsStateProps {
  data?: Destination.Response;
  error?: string;
  isLoading?: boolean;
}

const initialDestinationsState: DestinationsStateProps = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialDestinationsState = () => deepClone(initialDestinationsState);

export const Destinations = (state = getInitialDestinationsState(), action: any) => {
  switch (action.type) {
    case FETCH_DESTINATIONS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_DESTINATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_DESTINATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_DESTINATIONS_RESET:
      return getInitialDestinationsState();
    default:
      return state;
  }
};
