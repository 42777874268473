import { InputFieldOnly } from 'components/FormFieldsComponent';
import { deepClone } from 'helpers';
import { required } from 'helpers/validators';
import { Destination } from 'models/Destination.d';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Field, reduxForm, reset, SubmissionError } from 'redux-form';
import { updateDestinations } from 'redux/destination';
import './styles.scss';

export interface EditDestinationModalComponentProps {
  isOpen?: boolean;
  onClose?: (data?: any) => void;
  handleSubmit: (data: any) => () => void;
  initialize: (data?: any) => void;
  pristine: boolean;
  destination?: Destination.Object;
  submitting: boolean;
  error: string;
}

let EditDestinationModalComponent: any = (props: EditDestinationModalComponentProps) => {
  const { error, submitting, handleSubmit, isOpen, onClose, initialize, destination } = props;

  const dispatch = useDispatch();
  const onSave = async values => {
    const payload: any = deepClone(values);

    try {
      if (payload && destination && destination._id) {
        const updateDestinationPayload = {
          ...payload,
        };
        const res = await updateDestinations({ destinationId: destination._id, payload: updateDestinationPayload });

        if (res && res.data._id) {
          dispatch(reset('editDestinationModalComponentForm'));
          onClose(res.data);
        }
      }
    } catch (e) {
      throw new SubmissionError({
        _error: e.message,
      });
    }
  };

  useEffect(() => {
    if (destination && destination._id) {
      initialize({
        name: destination.name,
      });
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination]);

  return (
    <Modal isOpen={isOpen} toggle={() => onClose()} className="edit-destination-modal">
      <ModalHeader toggle={() => onClose()}>Edit Destination</ModalHeader>
      <form onSubmit={handleSubmit(onSave)}>
        <ModalBody>
          <div className="edit-destination-modal-container">
            {error && (
              <div className="alert alert-danger" role="alert">
                <i className="mdi mdi-block-helper mr-2"></i> {error}
              </div>
            )}
            <div className="form-group">
              <Field
                name="name"
                id="destination"
                component={InputFieldOnly}
                type="text"
                placeholder="Destination"
                label="Destination"
                validate={[required]}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" disabled={submitting}>
            Update
          </Button>{' '}
          <Button type="button" color="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

EditDestinationModalComponent = reduxForm({
  form: 'editDestinationModalComponentForm',
})(EditDestinationModalComponent);

export default EditDestinationModalComponent;
