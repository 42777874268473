import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputFieldOnly, SelectFieldOnly } from 'components/FormFieldsComponent';
import { required, validURL } from 'helpers/validators';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { Field } from 'redux-form';
import Loader from 'components/Loader';

import './styles.scss';

export interface UploadDocumentSceneProps {
  form?: {
    pristine: boolean;
    submitting: boolean;
  };
  pageType?: string;
  error?: string;
  onSave?: (data: any) => void;
  onChangeHandler?: (event?: any) => void;
  handleResetFileUpload?: (event?: any) => void;
  selectedFile?: any;
  documentCategories?: any;
  documentSubCategories?: any;
  handleOnChangeCategory?: (category?: any) => void;
  formValues?: any;
  isLoading?: boolean;
  documentDetails?: any;
}

const UploadDocumentScene = (props: UploadDocumentSceneProps) => {
  const {
    error,
    pageType,
    form: { pristine, submitting },
    onSave,
    onChangeHandler,
    handleResetFileUpload,
    selectedFile,
    documentCategories,
    documentSubCategories,
    handleOnChangeCategory,
    formValues,
    isLoading,
    documentDetails,
  } = props;
  return (
    <div>
      <form onSubmit={onSave}>
        <Row>
          <Col lg={12} className="pr-2">
            <div>
              <Col lg={12} className="pl-2 label-header mt-4 mb-3">
                Upload Document
                {/* {isLoading && <Loader />} */}
              </Col>
              {error && (
                <div className="alert alert-danger" role="alert">
                  <i className="mdi mdi-block-helper mr-2"></i> {error}
                </div>
              )}
              <div>
                <Row>
                  <Col lg={6}>
                    <div className="form-group mb-3">
                      <Field
                        name="title"
                        id="title"
                        component={InputFieldOnly}
                        validate={[required]}
                        placeholder="Document Title"
                        label="Document Title"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    {documentCategories && documentCategories.list && documentCategories.list.length > 0 && (
                      <div className="form-group mb-3">
                        <Field
                          disabled={pageType === 'update'}
                          name="category"
                          id="category"
                          component={SelectFieldOnly}
                          validate={[required]}
                          placeholder="Category"
                          label="Category"
                          onChange={handleOnChangeCategory}
                        >
                          <option value="">Select Category</option>
                          {documentCategories.list.map(category => (
                            <option value={category._id} key={`category_${category._id}`}>
                              {category.title}
                            </option>
                          ))}
                        </Field>
                      </div>
                    )}
                  </Col>
                </Row>
                {(selectedFile || (formValues && formValues.category)) && (
                  <Row>
                    {documentSubCategories && documentSubCategories.list && documentSubCategories.list.length > 0 && (
                      <Col lg={6}>
                        <div className="form-group mb-3">
                          <Field
                            name="subCategory"
                            id="subCategory"
                            component={SelectFieldOnly}
                            validate={[required]}
                            placeholder="Sub Category"
                            label="Sub Category"
                          >
                            <option value="">Select Sub Category</option>
                            {documentSubCategories.list.map(subCategory => (
                              <option value={subCategory._id} key={`subCategory_${subCategory._id}`}>
                                {subCategory.title}
                              </option>
                            ))}
                          </Field>
                        </div>
                      </Col>
                    )}
                    <Col lg={6}>
                      {formValues && formValues.category && formValues.category === 'qualityMCCForms' && (
                        <div className="form-group mb-3">
                          <Field
                            name="filePath"
                            id="filePath"
                            component={InputFieldOnly}
                            validate={[required, validURL]}
                            placeholder="File Path"
                            label="File Path"
                          />
                        </div>
                      )}
                      {formValues && formValues.category && formValues.category !== 'qualityMCCForms' && (
                        <div className="form-group mb-3">
                          <label />
                          <div className="action cus-file-input" id="upload-PO">
                            <input
                              type="file"
                              name="file"
                              className="file-input cursor-pointer"
                              onChange={event => onChangeHandler(event)}
                              accept="application/pdf"
                              onClick={handleResetFileUpload}
                            />
                            <button type="button" className="btn btn-sm btn-success">
                              <FontAwesomeIcon icon={['fas', 'upload']} />
                              &nbsp; Upload
                            </button>
                            {selectedFile && <span>{selectedFile.name}</span>}
                            {!selectedFile &&
                              pageType === 'update' &&
                              documentDetails &&
                              documentDetails.category !== 'qualityMCCForms' && <span>{documentDetails.fileName}</span>}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex align-items-center">
          <button
            type="submit"
            className="btn btn-info waves-effect waves-light"
            disabled={
              pageType === 'add' &&
              ((formValues && formValues.category && formValues.category !== 'qualityMCCForms' && !selectedFile) ||
                pristine ||
                submitting ||
                isLoading)
            }
          >
            <i className="fa fa-save"></i> Save
          </button>
          {isLoading && (
            <div className="ml-2">
              <Loader />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default UploadDocumentScene;
