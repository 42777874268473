export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_STARTED = 'FETCH_USERS_STARTED';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const FETCH_USERS_RESET = 'FETCH_USERS_RESET';

// TODO: Define users payload
/**
 * Fetch Users
 */

export const fetchUsers = payload => ({
  type: FETCH_USERS,
  payload,
});

export const fetchUsersStarted = () => ({
  type: FETCH_USERS_STARTED,
  payload: {},
});

export const fetchUsersSuccess = payload => ({
  type: FETCH_USERS_SUCCESS,
  payload,
});

export const fetchUsersFail = payload => ({
  type: FETCH_USERS_FAIL,
  payload,
});

export const fetchUsersReset = () => ({
  type: FETCH_USERS_RESET,
  payload: {},
});

/**
 * Fetch User
 */

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_STARTED = 'FETCH_USER_STARTED';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
export const FETCH_USER_RESET = 'FETCH_USER_RESET';

export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

// TODO: Define user payload

export const fetchUser = payload => ({
  type: FETCH_USER,
  payload,
});

export const createUser = payload => ({
  type: CREATE_USER,
  payload,
});

export const updateUser = payload => ({
  type: UPDATE_USER,
  payload,
});

export const deleteUser = payload => ({
  type: DELETE_USER,
  payload,
});

export const deleteUserSuccess = payload => ({
  type: DELETE_USER_SUCCESS,
  payload,
});

export const fetchUserStarted = () => ({
  type: FETCH_USER_STARTED,
  payload: {},
});

export const fetchUserSuccess = payload => ({
  type: FETCH_USER_SUCCESS,
  payload,
});

export const fetchUserFail = payload => ({
  type: FETCH_USER_FAIL,
  payload,
});

export const fetchUserReset = () => ({
  type: FETCH_USER_RESET,
  payload: {},
});
