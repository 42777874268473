import { API } from 'helpers';

export const getUsers = async ({ params }) => {
  return await API().get('user', {
    params,
  });
};

export const getUser = async userId => {
  return await API().get(`user/${userId}`);
};

export const addUser = async ({ payload }) => {
  return await API().post(`user`, payload);
};

export const editUser = async ({ userId, payload }) => {
  return await API().put(`user/${userId}`, payload);
};

export const updateOrderTableFields = async ({ userId, payload }) => {
  return await API().put(`user/${userId}/configureOrderTableFields`, payload);
};

export const sortOrderTableFields = async ({ userId, payload }) => {
  return await API().put(`user/${userId}/sortField`, payload);
};

export const removeUser = async userId => {
  return await API().delete(`user/${userId}`);
};

export const getSalesUsers = async () => {
  return await API().get(`/user/getSalesUser`);
};
