// @flow
import { deepClone } from 'helpers';
import { getLoggedInUser } from 'helpers/authUtils';
import { Customer } from 'models/Customer.d';
import { User } from 'models/User.d';

import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_FAILED,
  FORGET_PASSWORD_RESET,
  FORGET_PASSWORD_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_RESET,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_RESET,
  REGISTER_USER_SUCCESS,
} from './actions';

type AuthAction = { type: string; payload: {} | string };

export interface LoginStateProps {
  data?: {
    accessToken: string;
    session: string;
    user: User.Object;
  };
  error?: string;
  isLoading?: boolean;
}

const initialLoginState: LoginStateProps = {
  data: getLoggedInUser(),
  isLoading: false,
  error: null,
};

export const getInitialLoginState = () => deepClone(initialLoginState);

export const Login = (state: LoginStateProps = getInitialLoginState(), action: AuthAction) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, isLoading: true };
    case LOGIN_USER_SUCCESS:
      return { ...state, data: action.payload, isLoading: false, error: null };
    case LOGIN_USER_FAILED:
      return { ...state, error: action.payload, isLoading: false };
    case LOGOUT_USER:
      return { ...state, data: {}, error: null, isLoading: false };

    case LOGIN_USER_RESET:
      return getInitialLoginState();

    default:
      return { ...state };
  }
};

export interface RegisterStateProps {
  data?: Customer.Object;
  error?: string;
  isLoading?: boolean;
}

const initialRegisterState: RegisterStateProps = {
  data: {},
  isLoading: false,
  error: null,
};

export const getInitialRegisterState = () => deepClone(initialRegisterState);

export const Register = (state: RegisterStateProps = getInitialLoginState(), action: AuthAction) => {
  switch (action.type) {
    case REGISTER_USER:
      return { ...state, isLoading: true };
    case REGISTER_USER_SUCCESS:
      return { ...state, data: action.payload, isLoading: false, error: null };
    case REGISTER_USER_FAILED:
      return { ...state, error: action.payload, isLoading: false };
    case REGISTER_USER_RESET:
      return getInitialRegisterState();

    default:
      return { ...state };
  }
};

export interface ForgotPasswordStateProps {
  data?: any;
  error?: string;
  isLoading?: boolean;
}

const initialForgotPasswordState: ForgotPasswordStateProps = {
  data: {},
  isLoading: false,
  error: null,
};

export const getInitialForgotPasswordState = () => deepClone(initialForgotPasswordState);

export const ForgotPassword = (state: ForgotPasswordStateProps = getInitialLoginState(), action: AuthAction) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      return { ...state, isLoading: true };
    case FORGET_PASSWORD_SUCCESS:
      return { ...state, passwordResetStatus: action.payload, isLoading: false, error: null };
    case FORGET_PASSWORD_FAILED:
      return { ...state, error: action.payload, isLoading: false };
    case FORGET_PASSWORD_RESET:
      return getInitialForgotPasswordState();

    default:
      return { ...state };
  }
};
