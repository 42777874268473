import { API } from 'helpers';

export const getDocumentCategory = async ({ params }) => {
  return await API().get('document/categories', {
    params,
  });
};

export const getDocumentSubCategory = async ({ params, categoryId }) => {
  return await API().get(`document/subCategories/${categoryId}`, {
    params,
  });
};

export const uploadDocument = async payload => {
  return await API().post(`document`, payload);
};

export const updateDocument = async ({ documentId, payload }) => {
  return await API().put(`document/${documentId}`, payload);
};

export const deleteDocument = async ({ documentId }) => {
  return await API().delete(`/document/${documentId}`);
};

export const getAllDocument = async ({ params }) => {
  return await API().get('/document', {
    params,
  });
};

export const getDocument = async documentId => {
  return await API().get(`document/${documentId}`);
};
