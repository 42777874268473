import { deepClone } from 'helpers';
import { User as UserRef } from 'models/User.d';

import {
  DELETE_USER_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_RESET,
  FETCH_USER_STARTED,
  FETCH_USER_SUCCESS,
  FETCH_USERS_FAIL,
  FETCH_USERS_RESET,
  FETCH_USERS_STARTED,
  FETCH_USERS_SUCCESS,
} from './actions';

/**
 * Users Data
 */

export interface UsersStateProps {
  data?: UserRef.Response;
  error?: string;
  isLoading?: boolean;
}

const initialUsersState: UsersStateProps = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialUsersState = () => deepClone(initialUsersState);

export const Users = (state = getInitialUsersState(), action: any) => {
  switch (action.type) {
    case FETCH_USERS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_USERS_RESET:
      return getInitialUsersState();
    default:
      return state;
  }
};

/**
 * User Data
 */

export interface UserStateProps {
  data?: UserRef.Object;
  error?: string;
  isLoading?: boolean;
  deleted?: boolean;
}
const initialUserState: UserStateProps = {
  data: {},
  error: null,
  isLoading: false,
  deleted: false,
};

export const getInitialUserState = () => deepClone(initialUserState);

export const User = (state = getInitialUserState(), action: any) => {
  switch (action.type) {
    case FETCH_USER_STARTED:
      return { ...state, isLoading: true };
    case FETCH_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
        deleted: false,
      };
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        data: {},
        error: null,
        isLoading: false,
        deleted: true,
      };
    }
    case FETCH_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        deleted: false,
      };
    case FETCH_USER_RESET:
      return getInitialUserState();
    default:
      return state;
  }
};
