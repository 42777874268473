import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchItemsStarted, fetchItemsFail, fetchItemsSuccess, FETCH_ITEMS } from './actions';
import { getItems } from './api';

/**
 * Get All Items
 */
export function* watchFetchItems(action: any) {
  yield put(fetchItemsStarted());
  try {
    const data = yield getItems(action.payload);
    yield put(fetchItemsSuccess(data.data));
  } catch (e) {
    yield put(fetchItemsFail(e.message));
  }
}

export function* fetchItemSaga() {
  yield takeEvery(FETCH_ITEMS, watchFetchItems);
}

/**
 * Items Saga
 */
function* itemSaga(): any {
  yield all([fork(fetchItemSaga)]);
}

export default itemSaga;
