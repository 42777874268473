import ConfirmationComponent from 'components/Modals/ConfirmationComponent';
import NoPermissionsComponent from 'components/NoPermissions/NoPermissionsComponent';
import { TableHeaderColumnProps } from 'components/Table/TableHeaderComponent';
import { deepClone } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Show } from 'react-redux-permission';
import { fetchDocuments, DocumentStateProps, deleteDocument } from 'redux/document';
import DocumentsScene from './DocumentsScene';

const DocumentsContainer = () => {
  const [apiParam, setApiParam] = useState({
    $limit: 10,
    $skip: 0,
    $sort: '-createdAt',
    $populate: 'category,subCategory',
  });
  const dispatch = useDispatch();
  const documentsState: DocumentStateProps = useSelector((state: any) => state.Documents);
  const { data: documents } = documentsState;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument]: any = useState(null);
  const handleDeleteDocument = async (documents?: any) => {
    setIsConfirmationModalOpen(true);
    setSelectedDocument(documents);
  };

  const onSort = (e, c: TableHeaderColumnProps) => {
    const sort = c.sort === 'asc' ? 'desc' : 'asc';
    setTableColumns(old => {
      const n = old.map(o => {
        if (o.key === c.key) {
          return {
            ...o,
            sort,
          };
        } else {
          return {
            ...o,
            sort: '',
          };
        }
      });
      return n;
    });
    setApiParam(d => {
      d['$sort'] = `${sort === 'asc' ? '+' : '-'}${c.key}`;
      d['$limit'] = 10;
      d['$skip'] = 0;
      const newValue = deepClone(d);
      return newValue;
    });
  };

  const [tableColumns, setTableColumns]: [TableHeaderColumnProps[], any] = useState([
    {
      title: 'Title',
      key: 'title',
      canSort: true,
      sort: '',
      onClick: onSort,
    },
    {
      title: 'Category',
      key: 'category',
      canSort: true,
      sort: '',
      onClick: onSort,
    },
    {
      title: 'Sub Category',
      key: 'subCategory',
      canSort: true,
      sort: '',
      onClick: onSort,
    },
    {
      title: 'Created At',
      key: 'createdAt',
      canSort: true,
      sort: '',
      onClick: onSort,
    },
    {
      title: 'Action',
      key: 'action',
      canSort: false,
      className: 'text-right',
      sort: '',
    },
  ]);

  useEffect(() => {
    dispatch(fetchDocuments({ params: apiParam }));
    return () => {};
  }, [apiParam, dispatch]);

  const onClickNext = e => {
    setApiParam(d => {
      d['$skip'] = d['$skip'] + d['$limit'];
      const newValue = deepClone(d);
      return newValue;
    });
  };

  const onClickPrev = e => {
    setApiParam(d => {
      d['$skip'] = d['$skip'] - d['$limit'];
      const newValue = deepClone(d);
      return newValue;
    });
  };

  const handleSearch = event => {
    const value = event.target.value;
    setApiParam(d => {
      if (value) {
        d['$search'] = value;
      } else {
        delete d['$search'];
      }
      d['$limit'] = 10;
      d['$skip'] = 0;
      const newValue = deepClone(d);
      return newValue;
    });
  };

  return (
    <>
      <DocumentsScene
        {...{
          handleSearch,
          onClickNext,
          onClickPrev,
          tableColumns,
          documentsState,
          documents,
          apiParam,
          handleDeleteDocument,
        }}
      />
      <ConfirmationComponent
        isOpen={isConfirmationModalOpen}
        modalTitle="Confirmation"
        modalBody="Are you sure you want to delete this document?"
        successButton="Delete!"
        onClose={async d => {
          setIsConfirmationModalOpen(false);
          if (d && selectedDocument && selectedDocument._id) {
            await deleteDocument({
              documentId: selectedDocument._id,
            });
            dispatch(
              fetchDocuments({
                params: apiParam,
              }),
            );
            setSelectedDocument(null);
          }
        }}
      />
    </>
  );
};

const DocumentsContainerPermission = (props: any) => (
  <Show when={['documents_view']} fallback={<NoPermissionsComponent />}>
    <DocumentsContainer {...props} />
  </Show>
);

export default DocumentsContainerPermission;
