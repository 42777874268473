import { API } from 'helpers';

export const getTasks = async ({ params }) => {
  return await API().get('customer/getDashboardTasks', {
    params,
  });
};

export const markPriceUpdated = async ({ customerId }) => {
  return await API().put(`customer/${customerId}/priceUpdate`);
};

export const markPriceApproved = async ({ customerId }) => {
  return await API().put(`customer/${customerId}/priceApprove`);
};

export const markEnterInSAP = async ({ customerId, payload }) => {
  return await API().put(`customer/${customerId}/enterInSAP`, payload);
};
