import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchTasksFail, fetchTasksStarted, fetchTasksSuccess, FETCH_TASKS } from './actions';
import { getTasks } from './api';

/**
 * Get All Tasks
 */
export function* watchFetchTasks(action: any) {
  yield put(fetchTasksStarted());
  try {
    const data = yield getTasks(action.payload);
    yield put(fetchTasksSuccess(data.data));
  } catch (e) {
    yield put(fetchTasksFail(e.message));
  }
}

export function* fetchTasksSaga() {
  yield takeEvery(FETCH_TASKS, watchFetchTasks);
}

/**
 * Tasks Saga
 */
function* customerSaga(): any {
  yield all([fork(fetchTasksSaga)]);
}

export default customerSaga;
