import ConfirmationComponent from 'components/Modals/ConfirmationComponent';
import NoPermissionsComponent from 'components/NoPermissions/NoPermissionsComponent';
import { TableHeaderColumnProps } from 'components/Table/TableHeaderComponent';
import { deepClone } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Show } from 'react-redux-permission';
import { DestinationsStateProps, fetchDestinations, deleteDestinations } from 'redux/destination';
import DestinationListScene from './DestinationListScene';
import AddDestinationModal from '../../components/Modals/AddDestinationModal/AddDestinationModalComponent';
import EditDestinationModal from '../../components/Modals/EditDestinationModal/EditDestinationModalComponent';

const DestinationListContainer = () => {
  const [apiParam, setApiParam] = useState({
    $limit: 10,
    $skip: 0,
    $sort: '-createdAt',
  });
  const dispatch = useDispatch();
  const destinationsState: DestinationsStateProps = useSelector((state: any) => state.Destinations);
  const { data: destinations } = destinationsState;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isAddDestinationModalOpen, setIsAddDestinationModalOpen] = useState(false);
  const [isEditDestinationModalOpen, setIsEditDestinationModalOpen] = useState(false);
  const [selectedDestination, setSelectedDestination]: any = useState(null);

  const handleDeleteDocument = async (destinations?: any) => {
    setIsConfirmationModalOpen(true);
    setSelectedDestination(destinations);
  };
  const handleAddDestination = open => {
    setIsAddDestinationModalOpen(open);
  };

  const handleEditDestination = (open, destination) => {
    setIsEditDestinationModalOpen(open);
    setSelectedDestination(destination);
  };
  const onSort = (e, c: TableHeaderColumnProps) => {
    const sort = c.sort === 'asc' ? 'desc' : 'asc';
    setTableColumns(old => {
      const n = old.map(o => {
        if (o.key === c.key) {
          return {
            ...o,
            sort,
          };
        } else {
          return {
            ...o,
            sort: '',
          };
        }
      });
      return n;
    });
    setApiParam(d => {
      d['$sort'] = `${sort === 'asc' ? '+' : '-'}${c.key}`;
      d['$limit'] = 10;
      d['$skip'] = 0;
      const newValue = deepClone(d);
      return newValue;
    });
  };
  const [tableColumns, setTableColumns]: [TableHeaderColumnProps[], any] = useState([
    {
      title: 'Name',
      key: 'name',
      canSort: true,
      sort: '',
      onClick: onSort,
    },
    {
      title: 'Created At',
      key: 'createdAt',
      canSort: true,
      sort: '',
      onClick: onSort,
    },
    {
      title: 'Action',
      key: 'action',
      canSort: false,
      className: 'text-right',
      sort: '',
    },
  ]);

  useEffect(() => {
    dispatch(fetchDestinations({ params: apiParam }));
    return () => {};
  }, [apiParam, dispatch]);

  const onClickNext = e => {
    setApiParam(d => {
      d['$skip'] = d['$skip'] + d['$limit'];
      const newValue = deepClone(d);
      return newValue;
    });
  };

  const onClickPrev = e => {
    setApiParam(d => {
      d['$skip'] = d['$skip'] - d['$limit'];
      const newValue = deepClone(d);
      return newValue;
    });
  };

  const handleSearch = event => {
    const value = event.target.value;
    setApiParam(d => {
      if (value) {
        d['$search'] = value;
      } else {
        delete d['$search'];
      }
      d['$limit'] = 10;
      d['$skip'] = 0;
      const newValue = deepClone(d);
      return newValue;
    });
  };

  return (
    <>
      <DestinationListScene
        {...{
          handleSearch,
          onClickNext,
          onClickPrev,
          tableColumns,
          destinationsState,
          destinations,
          apiParam,
          handleDeleteDocument,
          handleAddDestination,
          handleEditDestination,
        }}
      />
      {isAddDestinationModalOpen && (
        <AddDestinationModal
          isOpen={isAddDestinationModalOpen}
          onClose={d => {
            setIsAddDestinationModalOpen(false);
            if (d && d._id) {
              dispatch(fetchDestinations({ params: apiParam }));
            }
          }}
        />
      )}
      {selectedDestination && isEditDestinationModalOpen && (
        <EditDestinationModal
          destination={selectedDestination}
          isOpen={isEditDestinationModalOpen}
          onClose={d => {
            setIsEditDestinationModalOpen(false);
            if (d && d._id) {
              dispatch(fetchDestinations({ params: apiParam }));
            }
          }}
        />
      )}
      <ConfirmationComponent
        isOpen={isConfirmationModalOpen}
        modalTitle="Confirmation"
        modalBody="Are you sure you want to delete this destination ?"
        successButton="Delete!"
        onClose={async d => {
          setIsConfirmationModalOpen(false);
          if (d && selectedDestination && selectedDestination._id) {
            await deleteDestinations({
              destinationId: selectedDestination._id,
            });
            dispatch(
              fetchDestinations({
                params: apiParam,
              }),
            );
            setSelectedDestination(null);
          }
        }}
      />
    </>
  );
};

const destinationsContainerPermission = (props: any) => (
  <Show when={['destination_view']} fallback={<NoPermissionsComponent />}>
    <DestinationListContainer {...props} />
  </Show>
);

export default destinationsContainerPermission;
