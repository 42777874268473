export const FETCH_SIZES = 'FETCH_SIZES';
export const FETCH_SIZES_STARTED = 'FETCH_SIZES_STARTED';
export const FETCH_SIZES_SUCCESS = 'FETCH_SIZES_SUCCESS';
export const FETCH_SIZES_FAIL = 'FETCH_SIZES_FAIL';
export const FETCH_SIZES_RESET = 'FETCH_SIZES_RESET';

/**
 * Fetch Sizes
 */

export const fetchSizes = payload => ({
  type: FETCH_SIZES,
  payload,
});

export const fetchSizesStarted = () => ({
  type: FETCH_SIZES_STARTED,
  payload: {},
});

export const fetchSizesSuccess = payload => ({
  type: FETCH_SIZES_SUCCESS,
  payload,
});

export const fetchSizesFail = payload => ({
  type: FETCH_SIZES_FAIL,
  payload,
});

export const fetchSizesReset = () => ({
  type: FETCH_SIZES_RESET,
  payload: {},
});
