import { all, fork, put, takeEvery } from 'redux-saga/effects';

import {
  LOGIN_USER,
  loginUserFailed,
  loginUserSuccess,
  REGISTER_USER,
  registerUserFailed,
  registerUserSuccess,
} from './actions';
import { login, register } from './api';

/**
 * Login User
 */
export function* watchFetchLogin(action: any) {
  try {
    const data = yield login(action.payload);
    yield put(loginUserSuccess(data.data));
  } catch (e) {
    yield put(loginUserFailed(e.message));
  }
}

export function* fetchLoginSaga() {
  yield takeEvery(LOGIN_USER, watchFetchLogin);
}

/**
 * Register User
 */
export function* watchFetchRegister(action: any) {
  try {
    const data = yield register(action.payload);
    yield put(registerUserSuccess(data.data));
  } catch (e) {
    yield put(registerUserFailed(e.message));
  }
}

export function* fetchRegisterSaga() {
  yield takeEvery(REGISTER_USER, watchFetchRegister);
}

/**
 * Customer Saga
 */
function* authSaga(): any {
  yield all([fork(fetchLoginSaga), fork(fetchRegisterSaga)]);
}

export default authSaga;
