export const required = (value: any) => (value || typeof value === 'number' ? undefined : 'Required');

export const maxLength = (max: any) => (value: any) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min: any) => (value: any) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const number = (value: any) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

export const minValue = (min: any) => (value: any) => (value && value < min ? `Must be at least ${min}` : undefined);

export const email = (value: any) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;

export const alphaNumeric = (value: any) =>
  value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined;

export const validURL = (value: any) =>
  value && !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i.test(value)
    ? 'Please Enter Valid URL'
    : undefined;

export const phoneNumber = (value: any) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? 'Invalid phone number, must be 10 digits' : undefined;

export const confirmField = (fieldName: string) => (value: any, allValue: any) => {
  return value && fieldName && allValue && allValue[fieldName] && allValue[fieldName] !== value
    ? `Value must match with ${fieldName} field`
    : undefined;
};

export const regex = (regexFormat: any) => (value: any) => (!regexFormat.test(value) ? 'Invalid format' : undefined);
