import React from 'react';

export interface RouterContextModel {
  history?: any;
  location?: any;
  match?: any;
}

const initialContext: RouterContextModel = {};

export default React.createContext(initialContext);
