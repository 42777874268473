import { API } from 'helpers';

export const getForecasts = async ({ params }) => {
  return await API().get('forecast', {
    params,
  });
};

export const addForecast = async payload => {
  return await API().post('forecast', payload);
};

export const updateForecast = async ({ forecastId, payload }) => {
  return await API().put(`forecast/${forecastId}`, payload);
};

export const updateForecastValues = async ({ forecastId, payload }) => {
  return await API().put(`forecast/${forecastId}/updateValues`, payload);
};

export const getForecastValues = async payload => {
  return await API().post('forecast/getValues', payload);
};

export const deleteForecast = async ({ forecastId }) => {
  return await API().delete(`forecast/${forecastId}`);
};
