export const FETCH_DOCUMENTS = 'FETCH_DOCUMENTS';
export const FETCH_DOCUMENTS_STARTED = 'FETCH_DOCUMENTS_STARTED';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAIL = 'FETCH_DOCUMENTS_FAIL';
export const FETCH_DOCUMENTS_RESET = 'FETCH_DOCUMENTS_RESET';

export const fetchDocuments = payload => ({
  type: FETCH_DOCUMENTS,
  payload,
});

export const fetchDocumentsStarted = () => ({
  type: FETCH_DOCUMENTS_STARTED,
  payload: {},
});

export const fetchDocumentsSuccess = payload => ({
  type: FETCH_DOCUMENTS_SUCCESS,
  payload,
});

export const fetchDocumentsFail = payload => ({
  type: FETCH_DOCUMENTS_FAIL,
  payload,
});

export const fetchDocumentsReset = () => ({
  type: FETCH_DOCUMENTS_RESET,
  payload: {},
});

export const FETCH_DOCUMENT_SUBCATEGORY = 'FETCH_DOCUMENT_SUBCATEGORY';
export const FETCH_DOCUMENT_SUBCATEGORY_STARTED = 'FETCH_DOCUMENT_SUBCATEGORY_STARTED';
export const FETCH_DOCUMENT_SUBCATEGORY_SUCCESS = 'FETCH_DOCUMENT_SUBCATEGORY_SUCCESS';
export const FETCH_DOCUMENT_SUBCATEGORY_FAIL = 'FETCH_DOCUMENT_SUBCATEGORY_FAIL';
export const FETCH_DOCUMENT_SUBCATEGORY_RESET = 'FETCH_DOCUMENT_SUBCATEGORY_RESET';

export const fetchDocumentSubCategory = payload => ({
  type: FETCH_DOCUMENT_SUBCATEGORY,
  payload,
});

export const fetchDocumentSubCategoryStarted = () => ({
  type: FETCH_DOCUMENT_SUBCATEGORY_STARTED,
  payload: {},
});

export const fetchDocumentSubCategorySuccess = payload => ({
  type: FETCH_DOCUMENT_SUBCATEGORY_SUCCESS,
  payload,
});

export const fetchDocumentSubCategoryFail = payload => ({
  type: FETCH_DOCUMENT_SUBCATEGORY_FAIL,
  payload,
});

export const fetchDocumentSubCategoryReset = () => ({
  type: FETCH_DOCUMENT_SUBCATEGORY_RESET,
  payload: {},
});
