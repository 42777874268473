export const FETCH_DESCRIPTIONS = 'FETCH_DESCRIPTIONS';
export const FETCH_DESCRIPTIONS_STARTED = 'FETCH_DESCRIPTIONS_STARTED';
export const FETCH_DESCRIPTIONS_SUCCESS = 'FETCH_DESCRIPTIONS_SUCCESS';
export const FETCH_DESCRIPTIONS_FAIL = 'FETCH_DESCRIPTIONS_FAIL';
export const FETCH_DESCRIPTIONS_RESET = 'FETCH_DESCRIPTIONS_RESET';

/**
 * Fetch Descriptions
 */

export const fetchDescriptions = payload => ({
  type: FETCH_DESCRIPTIONS,
  payload,
});

export const fetchDescriptionsStarted = () => ({
  type: FETCH_DESCRIPTIONS_STARTED,
  payload: {},
});

export const fetchDescriptionsSuccess = payload => ({
  type: FETCH_DESCRIPTIONS_SUCCESS,
  payload,
});

export const fetchDescriptionsFail = payload => ({
  type: FETCH_DESCRIPTIONS_FAIL,
  payload,
});

export const fetchDescriptionsReset = () => ({
  type: FETCH_DESCRIPTIONS_RESET,
  payload: {},
});
