import { deepClone } from 'helpers';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchForecastsStarted, fetchForecastsFail, fetchForecastsSuccess, FETCH_FORECASTS } from './actions';
import { getForecasts, getForecastValues } from './api';

/**
 * Get All Forecasts
 */
export function* watchFetchForecasts(action: any) {
  yield put(fetchForecastsStarted());
  try {
    const forecastsPayload = deepClone(action.payload);
    delete forecastsPayload.params.startDate;
    delete forecastsPayload.params.endDate;
    const res = yield getForecasts(forecastsPayload);
    let response = res && res.data ? res.data : null;
    if (response && response.list && response.list.length > 0) {
      const forecastIds = response.list.map(fs => fs._id);
      const values = yield getForecastValues({
        forecastIds,
        startDate: action.payload.params.startDate,
        endDate: action.payload.params.endDate,
      });
      if (values && values.data && values.data.length > 0) {
        response.list = response.list.map(fs => {
          const valuesData = values.data.filter(value => value.forecast === fs._id);
          if (valuesData && valuesData.length > 0) {
            fs.forecastValues = valuesData;
          }
          return fs;
        });
      }
    }
    yield put(fetchForecastsSuccess(response));
  } catch (e) {
    yield put(fetchForecastsFail(e.message));
  }
}

export function* fetchForecastsSaga() {
  yield takeEvery(FETCH_FORECASTS, watchFetchForecasts);
}

/**
 * Forecasts Saga
 */
function* forecastSaga(): any {
  yield all([fork(fetchForecastsSaga)]);
}

export default forecastSaga;
