import { deepClone } from 'helpers';
import { Forecast } from 'models/Forecast.d';
import _ from 'lodash';

import {
  FETCH_FORECASTS_FAIL,
  FETCH_FORECASTS_RESET,
  FETCH_FORECASTS_STARTED,
  FETCH_FORECASTS_SUCCESS,
  UPDATE_FORECAST_VALUE,
} from './actions';

/**
 * Forecasts Data
 */

export interface ForecastsStateProps {
  data?: Forecast.Response;
  error?: string;
  isLoading?: boolean;
}

const initialForecastsState: ForecastsStateProps = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialForecastsState = () => deepClone(initialForecastsState);

export const Forecasts = (state = getInitialForecastsState(), action: any) => {
  switch (action.type) {
    case FETCH_FORECASTS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_FORECASTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_FORECASTS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case UPDATE_FORECAST_VALUE:
      if (action.payload._id && state && state.data && state.data.list && state.data.list.length > 0) {
        const index = state.data.list.findIndex((fs: Forecast.Object) => fs._id === action.payload.forecast);
        if (index > -1) {
          // state.data.list[index];
          if (
            state.data.list[index] &&
            state.data.list[index].forecastValues &&
            state.data.list[index].forecastValues.length > 0
          ) {
            const valueIndex = state.data.list[index].forecastValues.findIndex(vl => vl._id === action.payload._id);
            if (valueIndex > -1) {
              // Update Value
              state.data.list[index].forecastValues.splice(valueIndex, 1, action.payload);
            } else {
              // Add value if new
              state.data.list[index].forecastValues.push(action.payload);
            }
          } else {
            // Add value if first value of forecast
            state.data.list[index].forecastValues = [action.payload];
          }
        }
      }
      return _.assignIn({}, state, {
        data: state.data,
        // error: null,
        // isLoading: false,
      });
    case FETCH_FORECASTS_RESET:
      return getInitialForecastsState();
    default:
      return state;
  }
};
