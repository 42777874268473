import { deepClone } from 'helpers';
import { Description } from 'models/Description.d';
import {
  FETCH_DESCRIPTIONS_FAIL,
  FETCH_DESCRIPTIONS_RESET,
  FETCH_DESCRIPTIONS_STARTED,
  FETCH_DESCRIPTIONS_SUCCESS,
} from './actions';

/**
 * Descriptions Data
 */
export interface DescriptionsStateProps {
  data?: Description.Response;
  error?: string;
  isLoading?: boolean;
}

const initialDescriptionsState: DescriptionsStateProps = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialDescriptionsState = () => deepClone(initialDescriptionsState);

export const Descriptions = (state = getInitialDescriptionsState(), action: any) => {
  switch (action.type) {
    case FETCH_DESCRIPTIONS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_DESCRIPTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_DESCRIPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_DESCRIPTIONS_RESET:
      return getInitialDescriptionsState();
    default:
      return state;
  }
};
