import axios, { AxiosInstance } from 'axios';
import { config } from 'config';

let axiosInstance = null;

const generateQueryString = queryObject => {
  let queryString = '';
  if (queryObject) {
    const queryKeys = Object.keys(queryObject);
    // eslint-disable-next-line
    queryKeys.forEach((key, index) => {
      if (queryObject[key]) {
        if (queryObject[key].toString().length) {
          queryString += `${key}=${queryObject[key]}&`;
        }
      }
    });
    if (queryKeys.length > 0 && queryString[queryString.length - 1] === '&') {
      queryString = queryString.slice(0, -1);
    }
  }
  return queryString;
};

const parseQueryString = queryString => {
  const search = queryString.substring(1);
  try {
    if (search) {
      return JSON.parse(
        '{"' +
          decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}',
      );
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};

const removeEmpty = obj => {
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === '' ||
      (Array.isArray(obj[propName]) && !obj[propName].length)
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

const deepClone = obj => {
  return JSON.parse(JSON.stringify(obj));
};

const startOfWeek = (date, startWeekDay = 1) => {
  const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -5 : startWeekDay);
  return new Date(date.setDate(diff));
};

const endOfWeek = (date, isWithSunday = false) => {
  const lastDay = date.getDate() - (date.getDay() - 1) + (isWithSunday ? 6 : 5);
  return new Date(date.setDate(lastDay));
};

const getBetweenDates = (startDate, stopDate) => {
  const dateArray = [];
  const currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
};

const getWeeks = (startDate, weekCount, isPrev = false) => {
  const dateArray = [];
  const currentDate = startDate;
  let i = 1;
  while (i <= weekCount) {
    dateArray.push(new Date(currentDate));
    const days = isPrev ? currentDate.getDate() - 7 : currentDate.getDate() + 7;
    currentDate.setDate(days);
    i++;
  }
  return isPrev ? dateArray.reverse() : dateArray;
};

const API = (): AxiosInstance => {
  if (axiosInstance) {
    return axiosInstance;
  }

  axiosInstance = axios.create({
    baseURL: config.API_ENDPOINT,
  });

  /**
   * Move to AppShellComponent
  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    config => {
      if (isUserAuthenticated()) {
        const auth = getLoggedInUser();
        if (auth && auth.accessToken) {
          config.headers['authorization'] = auth.accessToken;
        }
      }
      return config;
    },
    error => {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    response => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    error => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject((error.response && error.response.data) || error);
    },
  );
 */
  return axiosInstance;
};

const getOrderStatusRoleAndStatus = (order, role) => {
  const currentStatus = order.status;
  let statusList = [];
  if (role === 'customer_facing_manager') {
    if (currentStatus === 'submitted') {
      statusList = ['approved', 'hold', 'confirmed'];
    } else if (currentStatus === 'hold' || currentStatus === 'confirmed') {
      if (!order.isApproved) {
        statusList = ['approved'];
        if (currentStatus === 'hold') {
          statusList.push('confirmed');
        }
        if (currentStatus === 'confirmed') {
          statusList.push('hold');
        }
      } else if (order.isApproved && order.isBuilt) {
        statusList = ['delivered'];
        if (currentStatus === 'hold') {
          statusList.push('confirmed');
        }
        if (currentStatus === 'confirmed') {
          statusList.push('hold');
        }
      }
    } else if (order.isApproved && order.isBuilt && currentStatus === 'built') {
      statusList = ['delivered', 'hold', 'confirmed'];
    }
  } else if (role === 'logistic_coordinator') {
    if (order.isApproved && currentStatus === 'approved') {
      statusList = ['built', 'hold', 'confirmed'];
    } else if (order.isBuilt && currentStatus === 'built') {
      statusList = ['hold', 'confirmed', 'delivered'];
    } else if (order.isApproved && (currentStatus === 'hold' || currentStatus === 'confirmed')) {
      if (currentStatus === 'hold') {
        statusList.push('confirmed');
      }
      if (currentStatus === 'confirmed') {
        statusList.push('hold');
      }
      if (order.isBuilt) {
        statusList.push('delivered');
      } else if (order.isApproved) {
        statusList.push('built');
      }
    }
  } else if (role === 'admin') {
    if (currentStatus === 'submitted') {
      statusList = ['approved', 'hold', 'confirmed'];
    } else if (currentStatus === 'hold' || currentStatus === 'confirmed') {
      if (!order.isApproved) {
        statusList = ['approved'];
        if (currentStatus === 'hold') {
          statusList.push('confirmed');
        }
        if (currentStatus === 'confirmed') {
          statusList.push('hold');
        }
      } else if (order.isApproved) {
        if (currentStatus === 'hold') {
          statusList.push('confirmed');
        }
        if (currentStatus === 'confirmed') {
          statusList.push('hold');
        }
        if (order.isBuilt) {
          statusList.push('delivered');
        } else if (order.isApproved) {
          statusList.push('built');
        }
      }
    } else if (order.isApproved && currentStatus === 'approved') {
      statusList = ['built', 'hold', 'confirmed'];
    } else if (order.isApproved && order.isBuilt && currentStatus === 'built') {
      statusList = ['delivered', 'hold', 'confirmed'];
    }
  }
  return statusList;
};

export {
  generateQueryString,
  parseQueryString,
  removeEmpty,
  deepClone,
  API,
  startOfWeek,
  endOfWeek,
  getBetweenDates,
  getWeeks,
  getOrderStatusRoleAndStatus,
};
