export const FETCH_DESTINATIONS = 'FETCH_DESTINATIONS';
export const FETCH_DESTINATIONS_STARTED = 'FETCH_DESTINATIONS_STARTED';
export const FETCH_DESTINATIONS_SUCCESS = 'FETCH_DESTINATIONS_SUCCESS';
export const FETCH_DESTINATIONS_FAIL = 'FETCH_DESTINATIONS_FAIL';
export const FETCH_DESTINATIONS_RESET = 'FETCH_DESTINATIONS_RESET';

/**
 * Fetch Destinations
 */

export const fetchDestinations = payload => ({
  type: FETCH_DESTINATIONS,
  payload,
});

export const fetchDestinationsStarted = () => ({
  type: FETCH_DESTINATIONS_STARTED,
  payload: {},
});

export const fetchDestinationsSuccess = payload => ({
  type: FETCH_DESTINATIONS_SUCCESS,
  payload,
});

export const fetchDestinationsFail = payload => ({
  type: FETCH_DESTINATIONS_FAIL,
  payload,
});

export const fetchDestinationsReset = () => ({
  type: FETCH_DESTINATIONS_RESET,
  payload: {},
});
