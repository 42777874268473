import { deepClone } from 'helpers';
import { Item } from 'models/Item.d';
import { FETCH_ITEMS_FAIL, FETCH_ITEMS_RESET, FETCH_ITEMS_STARTED, FETCH_ITEMS_SUCCESS } from './actions';

/**
 * Items Data
 */
export interface ItemsStateProps {
  data?: Item.Response;
  error?: string;
  isLoading?: boolean;
}

const initialItemsState: ItemsStateProps = {
  data: {},
  error: null,
  isLoading: false,
};

export const getInitialItemsState = () => deepClone(initialItemsState);

export const Items = (state = getInitialItemsState(), action: any) => {
  switch (action.type) {
    case FETCH_ITEMS_STARTED:
      return { ...state, isLoading: true };
    case FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: null,
        isLoading: false,
      };
    case FETCH_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case FETCH_ITEMS_RESET:
      return getInitialItemsState();
    default:
      return state;
  }
};
