import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface TableHeaderColumnProps {
  title?: string;
  key?: string;
  canSort?: boolean;
  sort?: string | 'asc' | 'desc';
  onClick?: (e: any, c: any) => void;
  className?: string;
}

export interface TableHeaderComponentProps {
  className?: string;
  columns?: TableHeaderColumnProps[];
}

const TableHeaderComponent = (props: TableHeaderComponentProps) => {
  const { className = '', columns } = props;
  return (
    <thead className={className}>
      <tr>
        {columns &&
          columns.length > 0 &&
          columns.map((c, k) => (
            <th
              onClick={e => {
                if (typeof c.onClick === 'function' && c.canSort) {
                  c.onClick(e, c);
                }
              }}
              className={`${c.sort} ${c.canSort ? 'sorting' : ''} ${c.className || ''}`}
              key={`tableHeader${c.key}_$`}
            >
              {c.title}
              {c.canSort && c.sort !== 'asc' && c.sort !== 'desc' && <FontAwesomeIcon icon={['far', 'sort']} />}
              {c.canSort && c.sort === 'asc' && <FontAwesomeIcon icon={['far', 'sort-up']} />}
              {c.canSort && c.sort === 'desc' && <FontAwesomeIcon icon={['far', 'sort-down']} />}
            </th>
          ))}
      </tr>
    </thead>
  );
};

export default TableHeaderComponent;
