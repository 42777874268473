import { API } from 'helpers';

export const getOrders = async ({ params }) => {
  return await API().get('order', {
    params,
  });
};

export const addOrder = async ({ poId, payload }) => {
  return await API().post(`order/${poId}`, payload);
};

export const updateOrder = async ({ orderId, payload }) => {
  return await API().put(`order/${orderId}`, payload);
};

export const updateOrderStatus = async ({ orderId, payload }) => {
  return await API().put(`order/${orderId}/updateStatus`, payload);
};

export const deleteOrder = async ({ orderId }) => {
  return await API().delete(`order/${orderId}`);
};
