import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface ConfirmationComponentProps {
  isOpen?: boolean;
  onClose?: (data?: any) => void;
  className?: string;
  modalTitle?: string;
  modalBody?: string;
  successButton?: string;
  cancelButton?: string;
}

const ConfirmationComponent = (props: ConfirmationComponentProps) => {
  const {
    onClose,
    isOpen = false,
    className = '',
    cancelButton = 'Cancel',
    modalBody = 'Are you sure you want to delete?',
    modalTitle = 'Delete Confirmation!',
    successButton = 'Yes Delete it!',
  } = props;

  return (
    <Modal isOpen={isOpen} toggle={() => onClose()} className={className}>
      <ModalHeader toggle={() => onClose()}>
        <div dangerouslySetInnerHTML={{ __html: modalTitle }} />
      </ModalHeader>
      <ModalBody dangerouslySetInnerHTML={{ __html: modalBody }} />
      <ModalFooter>
        <Button color="primary" onClick={() => onClose(true)}>
          {successButton}
        </Button>{' '}
        <Button color="secondary" onClick={() => onClose()}>
          {cancelButton}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationComponent;
