import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { fetchRegionsStarted, fetchRegionsFail, fetchRegionsSuccess, FETCH_REGIONS } from './actions';
import { getRegions } from './api';

/**
 * Get All Regions
 */
export function* watchFetchRegions(action: any) {
  yield put(fetchRegionsStarted());
  try {
    const data = yield getRegions(action.payload);
    yield put(fetchRegionsSuccess(data.data));
  } catch (e) {
    yield put(fetchRegionsFail(e.message));
  }
}

export function* fetchRegionsSaga() {
  yield takeEvery(FETCH_REGIONS, watchFetchRegions);
}

/**
 * Regions Saga
 */
function* regionSaga(): any {
  yield all([fork(fetchRegionsSaga)]);
}

export default regionSaga;
