import { InputFieldOnly } from 'components/FormFieldsComponent';
import { deepClone } from 'helpers';
import { required } from 'helpers/validators';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Field, reduxForm, reset, SubmissionError } from 'redux-form';
import { uploadDestinations } from 'redux/destination';
import './styles.scss';

export interface AddDestinationModalComponentProps {
  isOpen?: boolean;
  onClose?: (data?: any) => void;
  handleSubmit: (data: any) => () => void;
  pristine: boolean;
  submitting: boolean;
  error: string;
}

let AddDestinationModalComponent: any = (props: AddDestinationModalComponentProps) => {
  const { error, submitting, handleSubmit, isOpen, onClose } = props;

  const dispatch = useDispatch();

  const onSave = async values => {
    const payload: any = deepClone(values);
    try {
      if (payload) {
        const res = await uploadDestinations(payload);

        if (res && res.data._id) {
          dispatch(reset('addDestinationModalComponentForm'));
          onClose(res.data);
        }
      }
    } catch (e) {
      throw new SubmissionError({
        _error: e.message,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={() => onClose()} className="add-forecast-modal">
      <ModalHeader toggle={() => onClose()}>Add Destination</ModalHeader>
      <form onSubmit={handleSubmit(onSave)}>
        <ModalBody>
          <div className="edit-order-modal-container">
            {error && (
              <div className="alert alert-danger" role="alert">
                <i className="mdi mdi-block-helper mr-2"></i> {error}
              </div>
            )}
            <div className="form-group">
              <Field
                name="name"
                id="destination"
                component={InputFieldOnly}
                type="text"
                placeholder="Destination"
                label="Destination"
                validate={[required]}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" disabled={submitting}>
            Create
          </Button>{' '}
          <Button type="button" color="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

AddDestinationModalComponent = reduxForm({
  form: 'addDestinationModalComponentForm',
})(AddDestinationModalComponent);

export default AddDestinationModalComponent;
